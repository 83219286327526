<template>
  <div>
    <div
      class="modal fade px-0"
      id="editTemplate"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-md mx-0 mx-md-auto"
        role="document"
      >
        <div
          class="modal-content edit-template-modal animate-bottom br-18 b-gold os-regular"
          style="
            border: 0.5px solid #0b5786 !important;
            box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
          "
        >
          <div
            class="modal-header p-0 mb-0 d-flex align-items-center"
            style="border-bottom: 0.5px solid #707070 !important"
          >
            <span class="col-2"></span>
            <h5
              class="modal-title font-md-18 font-16 pb-12p pb-md-20 pt-md-22 pt-14p pl-3 col-8 text-center kallisto-bold"
              style="color: #d0d0d0"
              id="exampleModalLongTitle"
            >
              Edit templates
            </h5>
            <button
              @click="handleModalClose()"
              type="button"
              class="close m-0 col-2 pr-20 d-flex justify-content-end"
              data-dismiss="modal"
            >
              <img
                :src="getIcon('x-button.svg')"
                alt="x-button"
                class="x-button"
              />
            </button>
          </div>
          <div v-if="currentStep === 1">
            <div
              class="text-left pt-24 mb-4 d-block px-6"
              style="border-tops: 0.5px solid #93751f80 !important"
            >
              <p
                class="font-18 font-md-16 mb-0 font-weight-bold"
                style="color: #d0d0d0"
              >
                Select a template to edit
              </p>
            </div>
            <div
              class="modal-body px-0 pt-0 pb-0 text-left"
              style="border-radius: 0px 0px 18px 18px"
            >
              <div
                class="template-v-for border-top-0 mb-34"
                style="background: #181818"
              >
                <div
                  v-for="(template, i) in this.$store.state
                    .batchTemplateMultiSelect"
                  :key="i"
                  class="batch-list-div pt-0"
                >
                  <div class="mx-0 mt-3 px-6">
                    <div class="d-flex px-0 px-md-auto">
                      <div class="cursor-pointer">
                        <label class="checkbox-container mb-0">
                          <input
                            type="checkbox"
                            v-model="template.checked"
                            @click="
                              !template.checked
                                ? chosenTemplate(template)
                                : null
                            "
                          />
                          <span class="checkmark"></span>
                          <div>
                            <h2 class="font-14 font-md-16 m-0 os-bold pl-3">
                              {{ template.schedule_name }}
                            </h2>
                          </div>
                        </label>
                        <!-- <span class="font-md-14 font-12 ml-6">{{
                        template.public ? "Public" : "Private"
                      }}</span> -->
                        <span class="col-9 col-lg-12 font-12 font-md-14 px-0">
                          <p
                            class="d-flex flex-row font-12 font-md-14 text-left ml-9 text-muted"
                            :style="{
                              color: template.checked ? '#ffffff' : '#929292',
                            }"
                          >
                            {{ convertTime(template.local_times) }}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="justify-center d-flex mb-32">
              <div>
                <button
                  type="button"
                  class="px-10 py-2 os-semibold"
                  :class="
                    this.anySelected
                      ? 'dsl-dark-button-edit-template'
                      : 'dsl-dark-button-edit-template-disable'
                  "
                  :disabled="!anySelected"
                  @click="deleteSelectedSchedules"
                >
                  Delete
                </button>
                <button
                  type="button"
                  class="btn os-bold"
                  :class="
                    this.selectedTemplatesCount
                      ? 'btn-warning-edit-template'
                      : 'apply-button-disabled'
                  "
                  :disabled="selectedTemplatesCount != 1"
                  @click="gotoNext"
                >
                  Edit
                </button>
              </div>
              <!-- <div class="modal-footer d-flex justify-content-center">
              
            </div> -->
            </div>
          </div>

          <div v-else-if="currentStep === 2">
            <!-- Second part of the modal content -->
            <!-- <edit-template-modal-two></edit-template-modal-two> -->
            <div class="modal-body px-0 mr-0 pt-24 text-left os-regular">
              <div class="row px-6 mx-0 mt-0">
                <div class="col-lg-12 d-flex px-0 pt-0 pb-10p">
                  <label class="font-18 font-md-16 font-weight-bold mb-0">
                    Template Name
                  </label>
                </div>
                <div
                  class="col-lg-12 px-0 py-0 mb-2 border-bottom-white hide-scrollbar"
                >
                  <div class="form-group">
                    <input
                      v-model="templateName"
                      type="text"
                      name="name"
                      id="name-e"
                      class="form-control text-white text-left font-md-14"
                      placeholder="Enter a Title"
                    />
                  </div>
                  <span
                    v-if="this.errors[0].createTemplateNameError"
                    class="text-danger"
                    >{{ this.errors[0].createTemplateNameError }}</span
                  >
                </div>
              </div>
              <div class="row mx-0">
                <div class="col-md-12 px-6">
                  <div id="selectShowType">
                    <select
                      name="LeaveType"
                      v-model="availability"
                      aria-placeholder="Select"
                      class="form-control choose-an-act text-left font-weight-bold font-16 font-md-14"
                    >
                      <option value="" selected disabled>
                        Select show type
                      </option>
                      <option class="ml-3 text-left" value="public">
                        Public
                      </option>
                      <option class="ml-3 text-left" value="private">
                        Private
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mb-md-130 mb-2 mt-0 px-6 mx-0 pt-4">
                <div
                  class="col-12 mb-4 pl-0 pr-0 d-flex justify-content-between"
                >
                  <label class="font-18 font-md-16 font-weight-bold mb-2"
                    >Time Slots</label
                  >
                  <div>
                    <button
                      class="timeSlotsActionRemoveStyle px-4 py-1"
                      @click="timeSlotsActionRemove()"
                    >
                      <img
                        :src="getIcon('filter-collapse-minus-mobile.svg')"
                        alt="filter-collapse-minus-mobile"
                        class="filter-collapse-minus-mobile"
                      />
                    </button>

                    <span class="timeSlotsStyle py-1 px-3">{{
                      timeSlotsCount
                    }}</span>
                    <button
                      class="timeSlotsActionAddStyle px-4 py-1"
                      @click="timeSlotsActionAdd()"
                    >
                      <img
                        :src="getIcon('filter-collapse-plus-mobile.svg')"
                        alt="filter-collapse-plus-mobile"
                        class="filter-collapse-plus-mobile"
                      />
                    </button>
                  </div>
                </div>
                <div class="col-lg-12 px-0 py-0 pb-4 mb-2">
                  <div class="row time-slot-row">
                    <div
                      v-for="(ts, i) in TimeSlots"
                      :key="i"
                      class="col-4 d-flex align-items-top time-slot-col py-1 no-border"
                    >
                      <input
                        v-model="ts.timeSlot"
                        type="text"
                        class="form-control time-slots text-white font-md-14"
                        placeholder="HH:MM"
                        v-mask="timeMask"
                        aria-label="time slot"
                      />
                    </div>
                  </div>
                  <span
                    v-if="this.errors[0].createTemplateTimeSlotsError"
                    class="text-danger mt-5 d-inline-block"
                    >{{ this.errors[0].createTemplateTimeSlotsError }}</span
                  >
                  <span
                    v-if="this.errors[0].createTemplateTimeSlotsLengthError"
                    class="text-danger mt-5 d-inline-block"
                    >{{
                      this.errors[0].createTemplateTimeSlotsLengthError
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="justify-center d-flex mb-32">
              <button
                type="button"
                class="dsl-dark-button px-10 py-2"
                @click="goBack()"
              >
                Go back
              </button>
              <button
                type="button"
                class="btn btn-warning"
                @click="addATemplate()"
              >
                Apply edits
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="delete_success"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 716px; max-height: 344px"
      >
        <div class="modal-content animate-bottom br-18">
          <div class="p-0 modal-body br-18 b-gold">
            <div class="d-flex flex-column text-center">
              <img
                :src="getIcon('check-solid.svg')"
                alt="checked"
                class="checkin-complete mt-5"
              />
              <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                Template successfully deleted
              </p>
              <div>
                <button
                  data-dismiss="modal"
                  class="continue-btn os-bold mb-32 px-4"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateTemplateModal from "@/components/instructor/CreateTemplateModal.vue";
import EditTemplateModalTwoVue from "./EditTemplateModalTwo.vue";
import { API } from "aws-amplify";

import $ from "jquery";

export default {
  components: {
    CreateTemplateModal,
    "edit-template-modal-two": EditTemplateModalTwoVue,
  },
  data() {
    return {
      //Batch Apply Template Modal Data *********************
      chosenBatchTemplate: "",
      batchTemplateStartDate: "",
      batchTemplateEndDate: "",
      batchTemplateName: "",
      //   batchTemplateMultiSelect: [],
      templates: this.$store.state.templates,
      selectedTitleId: "",
      seatCapacity: "",
      availability: "pubic",
      //*********************
      currentStep: 1,
      templateName: "",
      errors: [
        {
          createTemplateNameError: "",
          createTemplateTimeSlotsError: "",
          createTemplateAvailabilityError: "",
        },
      ],

      TimeSlots: [
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
        { timeSlot: "" },
      ],
    };
  },
  computed: {
    selectedTemplatesCount() {
      return this.$store.state.batchTemplateMultiSelect.filter(
        (template) => template.checked
      ).length;
    },
    anySelected() {
      return this.$store.state.batchTemplateMultiSelect.some(
        (template) => template.checked
      );
    },
    timeSlotsCount() {
      return this.TimeSlots.length;
    },
  },
  mounted() {
    this.templateName = this.$store.state.editTemplateData.schedule_name;
    try {
      for (let [
        index,
        time,
      ] of this.$store.state.editTemplateData.local_times?.entries()) {
        var str = time.toString();

        if (str.length == 3) {
          str = "0" + str[0] + str[1] + str[2];
        }
        str = str[0] + str[1] + ":" + str[2] + str[3];
        this.TimeSlots[index].timeSlot = str;
      }
    } catch (error) {
      console.log("error", error);
    }
    // $("#editTemplate-2").modal("show");
  },
  methods: {
    goBack() {
      this.currentStep--;
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    handleModalClose() {
      this.currentStep = 1;

      this.$store.state.batchTemplateMultiSelect.forEach(
        (i) => (i.checked = false)
      );
    },
    timeSlotsActionAdd() {
      this.TimeSlots.push({
        timeSlot: "",
      });
    },
    timeSlotsActionRemove() {
      if (this.TimeSlots.length > 1) {
        this.TimeSlots.pop();
      }
    },
    async addATemplate() {
      // Validate template name
      if (this.templateName === "") {
        this.errors[0].createTemplateNameError =
          "Template name cannot be empty.";
      } else {
        this.errors[0].createTemplateNameError = "";
      }

      // Prepare time slots to post
      var timeSlotsToPost = [];
      this.TimeSlots.forEach((ts) => {
        if (ts.timeSlot != "") {
          var timeSplit = ts.timeSlot.toString().split(":");
          var timeSlotToPost = timeSplit[0] + timeSplit[1];
          timeSlotsToPost.push(parseInt(timeSlotToPost));
        }
      });

      // Validate time slots
      if (timeSlotsToPost.length == 0) {
        this.errors[0].createTemplateTimeSlotsError =
          "Please fill in at least one time slot.";
      } else {
        this.errors[0].createTemplateTimeSlotsError = "";
      }

      // Check for any errors
      if (
        this.errors[0].createTemplateTimeSlotsError != "" ||
        this.errors[0].createTemplateNameError != ""
      ) {
        return -1;
      } else {
        $("#editTemplate").modal("hide");
        var data = {
          body: {
            schedule_name: this.templateName,
            public: this.availability === "public",
            local_times: timeSlotsToPost,
          },
        };

        // Make the API call
        await API.patch(
          "asulink",
          `/scheduler/schedule/${this.$store.state.editTemplateData.schedule_id}`,
          data
        )
          .then(async () => {
            $("#edit-template-sucess").modal();

            await this.$store.dispatch("setListOfTemplates").then(async () => {
              var data = [];
              this.$store.state.templates.forEach((ts) => {
                var template = ts;
                template["checked"] = false;
                data.push(template);
              });
              this.$store.state.batchTemplateMultiSelect = data;
            });
          })
          .catch(() => {
            this.$toasted.show(`Error Occured`, {
              position: "top-right",
              duration: 5000,
            });
          });

        // Reset modal
        this.currentStep = 1;
        this.emptyModalData();
      }
    },

    gotoNext() {
      if (this.currentStep < 2) {
        this.currentStep++;
        const a = [];
        this.templateName = this.$store.state.editTemplateData.schedule_name;
        this.availability = "public";
        this.TimeSlots.forEach((i) => (i.timeSlot = ""));
        this.$store.state.editTemplateData.local_times.forEach((time, ind) => {
          var str = time.toString();

          if (str.length == 3) {
            str = "0" + str[0] + str[1] + str[2];
          }
          str = str[0] + str[1] + ":" + str[2] + str[3];
          this.TimeSlots[ind].timeSlot = str;
          a.push(time);
        });
      }
    },

    onChangeAct(event) {
      this.selectedTitleId = event.target.value;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },

    chosenTemplate(template) {
      this.$store.state.editTemplateData = template;
    },
    async deleteSelectedSchedules() {
      const selectedTemplates =
        this.$store.state.batchTemplateMultiSelect.filter(
          (template) => template.checked
        );
      const promises = selectedTemplates.map((template) =>
        this.$store.dispatch("deleteTemplate", template.schedule_id)
      );
      $("#editTemplate").modal("hide");
      await Promise.all(promises).then((responses) => {
        const success = responses.every((response) => !response.status);
        if (success) {
          $("#delete_success").modal("show");
        } else {
          this.$toasted.show("Template deletion unsuccessful", {
            position: "top-right",
            duration: 5000,
          });
        }
      });
      // Refresh template list after deletion
      this.$store.dispatch("fetchTemplates"); // Assuming you have an action to fetch the templates
    },
    async deleteSchedule(schedule_id) {
      $("#editTemplate").modal("hide");
      await this.$store
        .dispatch("deleteTemplate", schedule_id)
        .then((response) => {
          if (response.status) {
            this.$toasted.show("Template deletion unsuccessful", {
              position: "top-right",
              duration: 5000,
            });
          } else if (response) {
            this.$toasted.show("Template deleted successfully", {
              position: "top-right",
              duration: 5000,
            });
          }
        });
    },
    convertTime(times) {
      var newtimes = times.map(String);

      var timesToReturn = [];
      newtimes.forEach((time) => {
        var str = time;
        if (time.length == 3) {
          str = "0" + time;
        }
        var newTime = [str.slice(0, 2), ":", str.slice(2)].join("");
        // Check correct time format and split into components
        newTime = newTime
          .toString()
          .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [newTime];
        if (newTime.length > 1) {
          // If newTime format correct
          newTime = newTime.slice(1); // Remove full string match value
          newTime[5] = +newTime[0] < 12 ? "am" : "pm"; // Set AM/PM
          newTime[0] = +newTime[0] % 12 || 12; // Adjust hours
        }
        // return newTime.join (''); // return adjusted newTime or original string
        timesToReturn.push(newTime.join(""));
      });
      return timesToReturn
        .toString()
        .replace(/[\]']+/g, " ")
        .replace(/,/g, ", ");
    },
  },
};
</script>

<style lang="scss">
.dsl-dark-button {
  background: #313131 !important;
  color: #f1f1f1;
  border-radius: 10px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 16px;
  opacity: 0.7;
}
.no-border {
  border: none;
}
.button-edit {
  border: 1px solid #ffc627 !important;
  color: #151515 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  background-color: #ffc627;
  width: 120px;
  height: 42px;
  opacity: 0.2 !important;
}
.button-delete {
  font-size: 16px !important;
  border-radius: 8px !important;
  width: 120px;
  height: 42px;
}
.form-control {
  width: 100% !important;
}
.choose-an-act {
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  text-align: center;
}
#selectShowType {
  select {
    height: 53px;
    color: white;
    border: none;
    background-color: #2d2d2d !important;
  }
}
.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container .checkmark {
  width: 16px;
  height: 16px;
  background-color: black;
  border: 1px solid #0e4f74;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
}

.checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: #007dc5;
}

.checkbox-container .checkmark::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid BLACK;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  display: block;
}

@media only screen and (max-width: 767px) {
  .template-v-for::-webkit-scrollbar {
    display: none;
  }
}
.modal {
  input {
    background-color: #151515;
  }
  .modal-content {
    background-color: #151515;
  }
  .modal-header {
    border-bottom: 0.5px solid #ffc627;
  }
}
.del-template {
  cursor: pointer;
}

.edit-template-modal {
  background-color: #151515;
  color: white;
}

.choose-an-act {
  width: 100%;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  color: #ffc627;
}

.to-from-dates {
  width: 140px;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-left: 16px;
  color: white;
}
.datepicker {
  color: black;
}

.template-v-for {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  border-top: 0.5px solid #514421;
}

.vdp-datepicker {
  input {
    max-width: 8.5rem;
    max-height: 4rem;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    color: white;
    text-align: center;
  }
  .prev {
    background-color: grey !important;
    left: 12%;
  }

  .next {
    background-color: grey !important;
    right: 12%;
  }
}

.vdp-datepicker__calendar {
  color: white;
  background-color: #151515 !important;
  font-size: 12px !important;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    color: white;
    border-radius: 16px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: pink;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #4a4a4a;

    .popover-inner {
      background: $color;
      color: #ffc627;
      padding: 24px;
      border-radius: 0px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden="false"] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}
@media only screen and (min-width: 768px) {
  #editTemplate {
    .modal-content {
      min-width: 522px !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.apply-button-disabled {
  color: #151515 !important;
  font-size: 16px !important;
  border-radius: 8px !important;
  background-color: #ffc627;
  padding: 10px 0px;
  opacity: 0.2;
  width: 136px;
  height: 42px;
  margin-top: -4px !important;
}
.btn-warning-edit-template {
  border-radius: 8px;
  background: #ffc627;
  width: 136px;
  height: 42px;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin-top: -4px !important;
}
.dsl-dark-button-edit-template-disable {
  background: #2c2c2c !important;
  color: #f1f1f1;
  width: 136px;
  height: 42px;
  padding: 10px 42px 10px 43px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.7;
  margin-right: 14px;
}
.dsl-dark-button-edit-template {
  background: #2c2c2c !important;
  color: #f1f1f1;
  width: 136px;
  height: 42px;
  padding: 10px 42px 10px 43px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 14px;
}
</style>
