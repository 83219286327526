<template>
  <div class="px-0 py-0" style="background-color: #0e0e0e !important">
    <div class="scheduler-bg">
      <div class="container p-0 position-relative" style="top: 60%">
        <div
          class="w-100 text-left row px-3 px-md-0 justify-content-between m-0"
        >
          <span class="kallisto-bold font-md-32 font-20 col-8 p-0"
            >Scheduler</span
          >
          <div
            class="col-12 col-md-2 pr-0 d-flex d-md-none justify-content-between justify-content-md-end px-0"
            v-if="this.$store.state.role == 'admin'"
          >
            <div
              class="float-right templating-buttons px-3"
              v-if="$store.state.scheduler_pods"
            >
              <select
                name="LeaveType"
                @change="onChangePod($event)"
                v-model="this.$store.state.calendarSelectedPod"
                class="pod-select font-14 font-md-18 text-light os-bold pointer py-0 pr-2"
              >
                <option
                  v-for="option in converToArray(
                    this.$store.state.scheduler_pods
                  )"
                  :value="option.key"
                  :key="option.key"
                >
                  Pod {{ option.key }}
                </option>
              </select>
              <img
                height="10"
                width="10"
                :src="getIcon('chevron-down-solid.svg')"
                alt="check-solid-icon"
              />
            </div>
            <div class="d-block d-md-none">
              <button
                class="calendar-pod text-dark font-14 font-weight-bold mr-0"
                data-toggle="modal"
                data-target="#sessionSettings"
                data-dismiss="modal"
                style="padding: 7px 16px 10px 16px"
              >
                Session settings
                <img
                  class="mx-1"
                  height="12"
                  width="12"
                  :src="getIcon('big-cogwheel-black.svg')"
                  alt="big-cogwheel"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container px-0" style="background-color: #0e0e0e !important">
      <div
        v-if="this.$store.state.role == 'admin'"
        class="col-12 text-left justify-content-between pt-20 px-0 pb-8"
      >
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="templating-buttons font-weight-bold mb-2">
              <button
                class="font-14 cursor-pointer font-weight-bold"
                id="templateOptions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Template Options
                <img
                  class="ml-1 hide-hover"
                  :src="getIcon('chevron-down-solid.svg')"
                  alt="select option type"
                  width="10"
                />
                <img
                  class="ml-1 show-hover"
                  :src="getIcon('chevron-down-solid-black.svg')"
                  alt="select option type"
                  width="10"
                />
              </button>
              <div
                class="dropdown-menu dropdown-menu-options p-0"
                aria-labelledby="templateOptions"
                style="border: 1px solid gray"
              >
                <div class="d-flex flex-column">
                  <button
                    class="font-14 mb-0 text-left hover-button"
                    data-toggle="modal"
                    data-target="#createATemplate"
                  >
                    Create
                  </button>
                  <button
                    class="font-14 mb-0 text-left hover-button"
                    data-toggle="modal"
                    data-target="#editTemplate"
                    data-dismiss="modal"
                  >
                    Edit
                  </button>
                  <button
                    class="font-14 mb-0 text-left hover-button"
                    data-toggle="modal"
                    data-target="#batchApplyTemplate"
                    data-dismiss="modal"
                  >
                    Batch Apply
                  </button>
                </div>
              </div>
            </div>
            <div class="sessions-buttons font-weight-bold">
              <button
                class="font-14 cursor-pointer font-weight-bold"
                id="templateOptions"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Session options
                <img
                  class="ml-1 hide-hover"
                  :src="getIcon('chevron-down-solid.svg')"
                  alt="select option type"
                  width="10"
                />
                <img
                  class="ml-1 show-hover"
                  :src="getIcon('chevron-down-solid-black.svg')"
                  alt="select option type"
                  width="10"
                />
              </button>
              <div
                class="dropdown-menu dropdown-menu-session-options p-0"
                aria-labelledby="sessionOptions"
                style="border: 1px solid gray"
              >
                <div class="d-flex flex-column">
                  <button
                    data-toggle="modal"
                    data-target="#createSession"
                    data-dismiss="modal"
                    class="font-14 font-weight-bold text-left hover-button"
                    @click="onPodChange()"
                  >
                    Add a session
                  </button>
                  <button
                    data-toggle="modal"
                    data-target="#batchCancelTemplate"
                    data-dismiss="modal"
                    class="font-14 font-weight-bold text-left hover-button"
                  >
                    Batch Cancel
                  </button>
                  <button
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#import-shows"
                    class="font-14 font-weight-bold mb-2 text-left hover-button"
                  >
                    Import shows
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              $store.state.SchedulerSessions.length > 0 &&
              $store.state.role == 'admin'
            "
            class="my-0 d-flex pl-3"
          >
            <div class="dropdown-wrapper moduleAct-corses mr-12">
              <div @click="toggleDropdown" class="d-flex">
                <div
                  class="moduleAct font-weight-bold text-black text-capitalize mb-0 font-12 font-md-16"
                >
                  {{ selectedSubject || "All subjects" }}
                </div>
                <span
                  :class="{
                    'fa-chevron-down': !dropdownOpen,
                    'fa-chevron-up': dropdownOpen,
                  }"
                  class="fas arrow my-auto"
                ></span>
              </div>

              <div v-if="dropdownOpen" class="dropdown-options">
                <ul>
                  <li
                    @click="selectOption('All subjects')"
                    class="text-white pl-20"
                  >
                    All subjects
                  </li>
                  <li
                    v-for="(tlc, i) in showOptions"
                    :key="i"
                    @click="selectOption(tlc)"
                    class="text-white pl-20"
                  >
                    {{ tlc }}
                  </li>
                </ul>
              </div>
            </div>

            <button
              class="filter-button-mobile font-14 font-weight-bold"
              data-toggle="modal"
              data-target="#desktopFilterModal"
              style="width: 97px; height: 39px"
            >
              <img
                :src="getIcon('funnel-mobile.svg')"
                alt="funnel-mobile"
                class="pr-1"
              />
              Filters
            </button>

            <div
              class="modal fade p-0"
              id="desktopFilterModal"
              tabindex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                class="modal-dialog modal-md modal-dialog-centered"
                role="document"
              >
                <div class="modal-content">
                  <div
                    class="b-modal-header d-flex flex-column justify-content-between"
                    style="
                      border-bottom: 0.5px solid #707070 !important;
                      border: 0.5px solid #0b5786 !important;
                      border-radius: 15px;
                      box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
                    "
                  >
                    <div class="d-block">
                      <div
                        class="d-flex justify-content-between align-items-center py-3 px-4"
                        style="
                          border-bottom: 0.5px solid #707070 !important;
                          opacity: 0.598;
                        "
                      >
                        <a data-dismiss="modal" class="ml-32">
                          <img
                            height="12"
                            class="pointer x-button d-none"
                            :src="getIcon('x-button-mobile.svg')"
                            alt="x-button-mobile"
                          />
                        </a>
                        <span
                          class="d-flex filter-header-mobile kallisto-bold justify-content-center text-white flex-grow-1"
                        >
                          Select filters
                        </span>
                        <a data-dismiss="modal" class="">
                          <img
                            height="12"
                            class="pointer x-button"
                            :src="getIcon('x-button-mobile.svg')"
                            alt="x-button-mobile"
                          />
                        </a>
                      </div>
                      <div
                        id="filter-collapse-mobile"
                        class="mt-24"
                        style="background: #0e0e0e; border-radius: 16px"
                      >
                        <div class="mb-0 px-4">
                          <div
                            class="mb-3"
                            style="border-bottom: 0.5px solid #ffffff66"
                          >
                            <div class="pb-3">
                              Pod ({{ selectedPods.length }})
                            </div>
                            <div id="pod-collapse-mobile" class="collapse show">
                              <div class="py-1 container">
                                <div
                                  class="mb-2 row mt-0"
                                  v-for="(pod, po) in Math.ceil(
                                    getPods($store.state.SchedulerSessions)
                                      .length / 2
                                  )"
                                  :key="po"
                                >
                                  <div class="col-6 p-0">
                                    <label class="check-container">
                                      <input
                                        :id="
                                          getPods(
                                            $store.state.SchedulerSessions
                                          )[po]
                                        "
                                        type="checkbox"
                                        :checked="
                                          selectedPods.includes(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[po]
                                          )
                                        "
                                        @change="
                                          updatePods(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[po]
                                          )
                                        "
                                      />
                                      <span class="checkmark-mobile"></span>
                                      <span class=""
                                        ><span>Pod </span>
                                        <span class="mt-1">{{
                                          getPod(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[po]
                                          )
                                        }}</span></span
                                      >
                                    </label>
                                  </div>
                                  <div
                                    class="col-6 p-0"
                                    v-if="
                                      po +
                                        Math.ceil(
                                          getPods(
                                            $store.state.SchedulerSessions
                                          ).length / 2
                                        ) <
                                      getPods($store.state.SchedulerSessions)
                                        .length
                                    "
                                  >
                                    <label class="check-container">
                                      <input
                                        :id="
                                          getPods(
                                            $store.state.SchedulerSessions
                                          )[
                                            po +
                                              Math.ceil(
                                                getPods(
                                                  $store.state.SchedulerSessions
                                                ).length / 2
                                              )
                                          ]
                                        "
                                        type="checkbox"
                                        :checked="
                                          selectedPods.includes(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[
                                              po +
                                                Math.ceil(
                                                  getPods(
                                                    $store.state
                                                      .SchedulerSessions
                                                  ).length / 2
                                                )
                                            ]
                                          )
                                        "
                                        @change="
                                          updatePods(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[
                                              po +
                                                Math.ceil(
                                                  getPods(
                                                    $store.state
                                                      .SchedulerSessions
                                                  ).length / 2
                                                )
                                            ]
                                          )
                                        "
                                      />
                                      <span class="checkmark-mobile"></span>
                                      <span
                                        ><span>Pod </span>
                                        <span class="">{{
                                          getPod(
                                            getPods(
                                              $store.state.SchedulerSessions
                                            )[
                                              po +
                                                Math.ceil(
                                                  getPods(
                                                    $store.state
                                                      .SchedulerSessions
                                                  ).length / 2
                                                )
                                            ]
                                          )
                                        }}</span></span
                                      >
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="mb-3"
                            style="border-bottom: 0.5px solid #ffffff66"
                          >
                            <div class="py-3">
                              Shows ({{ selectedActs.length }})
                            </div>
                            <div
                              id="show-collapse-mobile"
                              class="collapse show"
                            >
                              <div class="py-1 container">
                                <div
                                  class="mb-2 row mt-0"
                                  v-for="(mod, mo) in Math.ceil(
                                    getModules($store.state.SchedulerSessions)
                                      .length / 2
                                  )"
                                  :key="mo"
                                >
                                  <div class="col-6 p-0">
                                    <label class="check-container">
                                      <input
                                        :id="
                                          getModules(
                                            $store.state.SchedulerSessions
                                          )[mo]
                                        "
                                        type="checkbox"
                                        :checked="
                                          selectedActs.includes(
                                            getModules(
                                              $store.state.SchedulerSessions
                                            )[mo]
                                          )
                                        "
                                        @change="
                                          updateModule(
                                            getModules(
                                              $store.state.SchedulerSessions
                                            )[mo]
                                          )
                                        "
                                      />
                                      <span class="checkmark-mobile"></span>
                                      {{
                                        getTitleData(
                                          getModules(
                                            $store.state.SchedulerSessions
                                          )[mo]
                                        ).shortname
                                      }}
                                    </label>
                                  </div>
                                  <div
                                    class="col-6 p-0"
                                    v-if="
                                      mo +
                                        Math.ceil(
                                          getModules(
                                            $store.state.SchedulerSessions
                                          ).length / 2
                                        ) <
                                      getModules($store.state.SchedulerSessions)
                                        .length
                                    "
                                  >
                                    <label class="check-container">
                                      <input
                                        :id="
                                          getModules(
                                            $store.state.SchedulerSessions
                                          )[
                                            mo +
                                              Math.ceil(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                ).length / 2
                                              )
                                          ]
                                        "
                                        type="checkbox"
                                        :checked="
                                          selectedActs.includes(
                                            getModules(
                                              $store.state.SchedulerSessions
                                            )[
                                              mo +
                                                Math.ceil(
                                                  getModules(
                                                    $store.state
                                                      .SchedulerSessions
                                                  ).length / 2
                                                )
                                            ]
                                          )
                                        "
                                        @change="
                                          updateModule(
                                            getModules(
                                              $store.state.SchedulerSessions
                                            )[
                                              mo +
                                                Math.ceil(
                                                  getModules(
                                                    $store.state
                                                      .SchedulerSessions
                                                  ).length / 2
                                                )
                                            ]
                                          )
                                        "
                                      />
                                      <span class="checkmark-mobile"></span>
                                      {{
                                        getTitleData(
                                          getModules(
                                            $store.state.SchedulerSessions
                                          )[
                                            mo +
                                              Math.ceil(
                                                getModules(
                                                  $store.state.SchedulerSessions
                                                ).length / 2
                                              )
                                          ]
                                        ).shortname
                                      }}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="mb-3"
                            style="border-bottom: 0.5px solid #ffffff66"
                          >
                            <div class="py-3">
                              Show type ({{ getActiveAudienceCount() }})
                            </div>
                            <div
                              id="audience-collapse-mobile"
                              class="collapse show"
                            >
                              <div class="py-1 d-flex">
                                <p class="mr-100">
                                  <label class="check-container">
                                    <input
                                      v-model="hiddenShows"
                                      type="checkbox"
                                      :checked="hiddenShows"
                                      @click="RF()"
                                    />
                                    <span class="checkmark-mobile"></span>
                                    Hidden shows
                                  </label>
                                </p>
                                <p class="mb-3">
                                  <label class="check-container">
                                    <input
                                      v-model="publicShows"
                                      type="checkbox"
                                      :checked="publicShows"
                                      @click="RF()"
                                    />
                                    <span class="checkmark-mobile"></span>
                                    Public shows
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="py-3">
                              Reservation type ({{
                                getActiveReservationCount()
                              }})
                            </div>
                            <div
                              id="audience-collapse-mobile"
                              class="collapse show"
                            >
                              <div class="py-1 d-flex">
                                <p class="mr-150">
                                  <label class="check-container">
                                    <input
                                      v-model="typeGroup"
                                      type="checkbox"
                                      :checked="typeGroup"
                                      @click="RF()"
                                    />
                                    <span class="checkmark-mobile"></span>
                                    Group
                                  </label>
                                </p>
                                <p class="mb-3">
                                  <label class="check-container">
                                    <input
                                      v-model="typeIndividual"
                                      type="checkbox"
                                      :checked="typeIndividual"
                                      @click="RF()"
                                    />
                                    <span class="checkmark-mobile"></span>
                                    Individual
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-center mb-32 mt-24">
                          <div class="d-flex">
                            <button
                              class="text-center font-weight-bold font-16 reset-button-filter"
                              @click="resetFilters"
                            >
                              Reset Filters
                            </button>
                          </div>
                          <div class="d-flex">
                            <button
                              data-dismiss="modal"
                              class="text-center font-weight-bold font-16 apply-filter-button-mobile"
                            >
                              Apply ({{ pills.length }})
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="pb-6 pt-22">
        <div class="row mx-0 justify-content-end">
          <div>
            <v-chip
              v-for="(pill, index) in pills"
              :key="index"
              class="mb-2 mr-2 custom-chip os-semibold"
              style="padding: 5px !important; height: 26px !important"
            >
              <img
                class="mr-1"
                src="@/assets/icons/pill-close-cross.svg"
                alt=""
                @click="removePill(pill.value)"
              />
              {{ pill.text }}
            </v-chip>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center" v-if="$store.state.loader">
        <lottie-player
          autoplay
          loop
          mode="normal"
          class="check-in-animation"
          :src="check_in_arrow_json"
          style="width: 100px; height: 100px"
        ></lottie-player>
      </div>
      <div v-else>
        <v-app class="px-3 px-md-0" v-if="$store.state.refresh">
          <v-row class="fill-height w-100 mx-0">
            <v-col class="px-0">
              <v-sheet height="64" class="pb-3 custom-v-head">
                <v-toolbar flat>
                  <div class="d-flex mr-3">
                    <img
                      class="pointer"
                      :src="getIcon('calendar-alt-regular.svg')"
                      alt="calendar-alt-solid"
                      width="20"
                    />
                    <datepicker
                      v-model="calendarDate"
                      :format="customFormatter"
                      :highlighted="getCurrentDate()"
                      class="session-date-selector-scheduler"
                      @closed="selectedDate(calendarDate)"
                    >
                    </datepicker>
                  </div>
                  <!-- <v-btn fab text small color="white" @click="prev">
                    <v-icon
                      small
                      style="width: 30px !important; height: 30px !important"
                    >
                      mdi-chevron-left
                    </v-icon>

                  </v-btn> -->
                  <img
                    :src="getIcon('chevron-left.svg')"
                    alt="chevronLeftIcon"
                    class=""
                    style="margin-right: 12px !important; margin-left: 4px"
                    @click="prev"
                  />

                  <p class="font-md-24 font-18 p-0 m-0">
                    {{ startMonth }} {{ startDate }} - {{ endMonth }}
                    {{ endDate }}, {{ year }}
                  </p>

                  <v-btn fab text small color="white" @click="next">
                    <!-- <v-icon
                      small
                      style="width: 30px !important; height: 30px !important"
                    >
                      mdi-chevron-right
                    </v-icon> -->
                    <img
                      :src="getIcon('chevron-right.svg')"
                      alt="chevronRightIcon"
                      class=""
                      style="margin-left: 12px !important"
                    />
                  </v-btn>
                  <v-spacer></v-spacer>
                  <div
                    v-if="this.$store.state.role == 'instructor'"
                    class="float-right mr-3 calendar-pod px-3"
                  >
                    <select
                      name="LeaveType"
                      @change="onChangePod($event)"
                      v-model="this.$store.state.calendarSelectedPod"
                      class="pod-select text-white font-14 font-weight-bold pointer py-0 pr-4"
                    >
                      <option
                        v-for="option in converToArray(
                          this.$store.state.scheduler_pods
                        )"
                        :value="option.key"
                        :key="option.key"
                      >
                        Pod {{ option.key }}
                      </option>
                    </select>
                    <img
                      height="10"
                      width="10"
                      :src="getIcon('chevron-down-solid.svg')"
                      alt="check-solid-icon"
                    />
                  </div>
                  <!-- <div
                  class="d-flex"
                  style="
                    border: 0.5px solid #707070 !important;
                    border-radius: 8px;
                  "
                >
                  <div
                    class="select-date-type cursor-pointer"
                    :class="type == 'day' ? 'active-date-type' : ''"
                    @click="type = 'day'"
                  >
                    Day
                  </div>
                  <div
                    class="select-date-type cursor-pointer"
                    :class="type == 'week' ? 'active-date-type' : ''"
                    @click="type = 'week'"
                  >
                    Week
                  </div>
                  <div
                    class="select-date-type cursor-pointer"
                    :class="type == 'month' ? 'active-date-type' : ''"
                    @click="type = 'month'"
                  >
                    Month
                  </div>
                </div> -->
                  <div>
                    <select
                      v-model="type"
                      @change="onTypeChange"
                      class="moduleAct font-weight-bold text-black text-capitalize mb-0 font-12 os-semibold"
                      style="
                        border: 0.5px solid #707070 !important;
                        width: 76px;
                        height: 28px;
                        background: white;
                        border-radius: 8px;
                        padding-top: 6px !important;
                        padding-bottom: 6px !important;
                        padding-right: 24px !important;
                        padding-left: 12px !important;
                        cursor: pointer;
                        border-radius: 3px;
                        background: #d0d0d0;
                      "
                    >
                      <option class="moduleAct" value="day">Day</option>
                      <option class="moduleAct" value="week">Week</option>
                      <option class="moduleAct" value="month">Month</option>
                    </select>
                    <!-- <span
                      class="fas arrow-calendar"
                      style="
                        width: 8px;
                        height: 5px;
                        margin-top: -5px;
                        margin-right: 5px;
                      "
                    ></span> -->
                    <span class="arrow-calendar">
                      <img :src="getIcon('arrow-down.svg')" alt="" />
                    </span>
                  </div>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600" class="custom-table">
                <v-calendar
                  :key="calendarKey"
                  ref="calendar"
                  v-model="focus"
                  color="primary"
                  :events="filteredEvents"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                  @click:time="openCreateSessionModal"
                  :event-overlap-mode="mode"
                  :event-overlap-threshold="30"
                  :dark="true"
                  :short-weekdays="false"
                  class="border-0"
                  :short-intervals="false"
                  @change="getEvents"
                >
                  <template v-slot:event="{ event }">
                    <template>
                      <div :class="borderOpacity(event)"></div>
                      <div
                        :class="sessionOpacity(event)"
                        data-toggle="modal"
                        data-target="#sessionInfoModal"
                      >
                        {{ start_and_end(event.name) }}
                      </div>
                    </template>
                  </template>
                  <template v-slot:day-body="{ date, week }">
                    <div
                      class="v-current-time"
                      :class="{ first: date === week[0].date }"
                      :style="{ top: nowY }"
                    ></div>
                  </template>
                  <template v-slot:day-label-header="{ day, month, year }">
                    <template>
                      <div class="font-14" @click="viewDay">
                        {{ month }}/{{ day }}/{{ year }}
                      </div>
                    </template>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
          </v-row>
          <div
            class="modal fade"
            id="sessionSettings"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            data-backdrop="static"
            v-if="this.$store.state.role == 'admin'"
          >
            <div class="modal-dialog modal-lg m-0" role="document">
              <div class="modal-content animate-left">
                <div class="modal-header p-0 my-5">
                  <p class="d-flex justify-content-between w-100 px-3">
                    <span class="font-18 kallisto-bold text-gold">
                      Session Settings</span
                    >
                    <img
                      data-dismiss="modal"
                      :src="getIcon('x-button.svg')"
                      alt="x-button"
                    />
                  </p>
                </div>
                <div
                  class="modal-body text-left os-regular d-flex align-items-center"
                >
                  <div class="w-100 font-16">
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4 border-bottom-gold"
                      data-toggle="modal"
                      data-target="#createATemplate"
                    >
                      <span>Create a template</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4 border-bottom-gold"
                      data-toggle="modal"
                      data-target="#editTemplate"
                    >
                      <span>Edit a template</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4 border-bottom-gold"
                      data-toggle="modal"
                      data-target="#batchApplyTemplate"
                    >
                      <span>Batch apply</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4 border-bottom-gold"
                      data-toggle="modal"
                      data-target="#batchCancelTemplate"
                    >
                      <span>Batch cancel</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4 border-bottom-gold"
                      data-toggle="modal"
                      data-target="#createSession"
                    >
                      <span>Add a session</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                    <p
                      class="my-0 kallisto-bold d-flex justify-content-between w-100 py-4"
                      data-toggle="modal"
                      data-target="#import-shows"
                    >
                      <span>Import shows</span
                      ><img
                        :src="getIcon('right-chevvr.svg')"
                        alt="right-chevvr"
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Create A Event Modal -->
          <div
            class="modal fade px-0"
            id="createSession"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            data-backdrop="static"
            v-if="this.$store.state.role == 'admin'"
          >
            <div
              class="modal-dialog modal-dialog modal-md br-18 b-gold mx-0 mx-md-auto"
              style="
                border: 0.5px solid #0b5786 !important;
                box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
                height: 626px;
                width: 522px;
              "
              role="document"
            >
              <div id="add_a_session" class="modal-content">
                <div
                  class="modal-header p-0 d-flex align-items-center"
                  style="
                    border-bottom: 0.5px solid #707070 !important;
                    opacity: 0.598;
                  "
                >
                  <span class="col-2"></span>
                  <h5
                    class="modal-title font-md-18 font-16 px-0 pb-12p pb-md-20 pt-md-22 pt-14p text-white col-8 text-center kallisto-bold"
                  >
                    Add a session
                  </h5>
                  <button
                    type="button"
                    class="close m-0 col-2 pr-20 d-flex justify-content-end"
                    data-dismiss="modal"
                    @click="emptyCreateSessionModalData()"
                  >
                    <img
                      :src="getIcon('x-button.svg')"
                      alt="x-button"
                      class="x-button"
                      @click="(step = 1), (showOptions = subjectOptions())"
                    />
                  </button>
                </div>
                <div class="modal-body text-left os-regular px-0 pt-24">
                  <div v-if="step === 1">
                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-0"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                          >Pod</label
                        >
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0">
                        <div class="d-flex">
                          <select
                            name="Pods"
                            v-model="selectedPod"
                            @change="onPodChange"
                            class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                          >
                            <option disabled selected value="">
                              Select a Pod
                            </option>
                            <option
                              v-for="option in converToArray(
                                $store.state.scheduler_pods
                              )"
                              :value="option.key"
                              :key="option.key"
                            >
                              Pod {{ option.key }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-0"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0 pt-4"
                        >
                          Reservation type
                        </label>
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0 d-flex">
                        <select
                          name="LeaveType"
                          v-model="reservationType"
                          placeholder="Select reservation type"
                          class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                        >
                          <option value="" selected disabled>
                            Select reservation type
                          </option>
                          <option class="ml-3 text-left" value="group">
                            Group reservation
                          </option>
                          <option value="individual">
                            Individual reservation
                          </option>
                        </select>
                        <span
                          v-if="this.errors[0].createSessionActNameError"
                          class="text-danger d-inline-block"
                          >{{ this.errors[0].createSessionActNameError }}</span
                        >
                      </div>
                    </div>

                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-4"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                        >
                          Subject
                        </label>
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0 d-flex">
                        <select
                          name="LeaveType"
                          @change="onChangeSubject($event)"
                          v-model="subjectId"
                          placeholder="Select subject"
                          class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                        >
                          <option
                            class="ml-3 text-left"
                            disabled
                            selected
                            value=""
                          >
                            Select subject
                          </option>
                          <option v-for="(tlc, i) in showOptions" :key="i">
                            {{ tlc }}
                          </option>
                        </select>
                        <span
                          v-if="this.errors[0].createSessionActNameError"
                          class="text-danger d-inline-block"
                          >{{ this.errors[0].createSessionActNameError }}</span
                        >
                      </div>
                    </div>

                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        :disabled="isNextDisabled"
                        type="button"
                        class="apply-button"
                        @click="gotoNext()"
                      >
                        <span class="font-weight-bold apply">Next</span>
                      </button>
                    </div>
                  </div>
                  <div v-if="step === 2">
                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-0"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                        >
                          Is this a recitation session?
                        </label>
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0">
                        <div class="d-flex">
                          <select
                            name="LeaveType"
                            aria-placeholder="Select"
                            v-model="recitation_session"
                            class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                          >
                            <option value="" selected disabled>Select</option>
                            <option class="ml-3 text-left" value="true">
                              Yes, this is a recitation
                            </option>
                            <option class="ml-3 text-left" value="false">
                              No, this is not a recitation
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div v-if="recitation_session == 'true'">
                      <div class="border-bottom-white px-6 mt-0">
                        <div
                          class="col-md-12 d-flex align-items-center pb-10p px-0 pt-0"
                        >
                          <label
                            class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                          >
                            Recitation section
                          </label>
                        </div>
                        <div class="col-md-12 pb-5 px-0 pt-0">
                          <div class="d-flex">
                            <select
                              name="LeaveType"
                              @change="onRecitation($event)"
                              aria-placeholder="Select"
                              class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                            >
                              <option value="" selected disabled>
                                Select section
                              </option>
                              <option
                                class="ml-3 text-left"
                                v-for="(recitations, rc) in recitation_sessions"
                                :key="rc"
                                :value="recitations.recitation_section"
                              >
                                {{ recitations.recitation_section }} - (
                                {{ recitations.recitation_meeting_day }} :
                                {{
                                  getParsedTime(
                                    recitations.recitation_meeting_start_time
                                  )
                                }}
                                to
                                {{
                                  getParsedTime(
                                    recitations.recitation_meeting_end_time
                                  )
                                }}
                                )
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="recitation_session == 'true'"
                      class="border-bottom-white px-6 mt-0"
                    >
                      <div>
                        <div
                          class="col-md-12 d-flex align-items-center pb-20 px-0"
                        >
                          <label
                            class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                          >
                            Course
                          </label>
                        </div>
                        <div class="col-md-12 pb-5 px-0 pt-0 d-flex">
                          <select
                            name="LeaveType"
                            @change="onChangeCourse($event)"
                            v-model="selectedCourse"
                            placeholder="Select course"
                            class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                          >
                            <option
                              class="ml-3 text-left"
                              disabled
                              selected
                              value
                            >
                              Select course
                            </option>
                            <option
                              v-for="(object, i) in $store.state.allCourses"
                              :key="i"
                            >
                              {{ object.course }}
                            </option>
                          </select>
                          <span
                            v-if="errors[0].createSessionActNameError"
                            class="text-danger d-inline-block"
                          >
                            {{ errors[0].createSessionActNameError }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-4"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                        >
                          Show
                        </label>
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0 d-flex">
                        <select
                          name="LeaveType"
                          @change="onChangeAct($event)"
                          v-model="selectedTitleId"
                          placeholder="Choose an act"
                          class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                        >
                          <option
                            class="ml-3 text-left"
                            disabled
                            selected
                            value
                          >
                            Choose an act
                          </option>
                          <option v-for="(act, i) in filteredTitles" :key="i">
                            {{ act.shortname }}
                          </option>
                        </select>
                        <span
                          v-if="errors[0].createSessionActNameError"
                          class="text-danger d-inline-block"
                          >{{ errors[0].createSessionActNameError }}</span
                        >
                      </div>
                    </div>
                    <div class="modal-footer justify-content-center">
                      <div class="d-flex">
                        <button
                          type="button"
                          class="reset-button-filter px-10 py-2"
                          @click="gotoPreviousStep()"
                        >
                          Go Back
                        </button>
                      </div>
                      <div class="d-flex">
                        <button
                          :disabled="isNextDisabled"
                          type="button"
                          class="apply-button"
                          @click="gotoNextStep3()"
                        >
                          <span class="font-weight-bold apply">Next</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="step === 3">
                    <div class="border-bottom-white px-6 mt-0">
                      <div
                        class="col-md-12 d-flex align-items-center pb-10p px-0 pt-4"
                      >
                        <label
                          class="font-18 font-md-16 font-weight-bold pb-0 mb-0"
                        >
                          Show type
                        </label>
                      </div>
                      <div class="col-md-12 pb-5 px-0 pt-0">
                        <div class="d-flex">
                          <select
                            name="LeaveType"
                            v-model="availability"
                            aria-placeholder="Select"
                            class="form-control selectstyle col-md-12 choose-an-act text-left font-weight-bold font-16 font-md-14"
                          >
                            <option value="" selected disabled>
                              Select show type
                            </option>
                            <option
                              class="ml-3 text-left"
                              value="public"
                              v-if="dynamicShow()"
                            >
                              Public
                            </option>
                            <option class="ml-3 text-left" value="private">
                              Private
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex border-bottom-white px-6 mb-130">
                      <div class="pb-2 pr-24">
                        <div class="col-lg-12 pt-md-2 pt-4 pb-0 px-0">
                          <label
                            class="font-18 font-md-16 font-weight-bold mb-0 pb-10p"
                            >Date</label
                          >
                        </div>
                        <div class="col-lg-12 pt-0 px-0">
                          <datepicker
                            v-model="createStartDate"
                            :format="customFormatter"
                            :highlighted="getCurrentDate()"
                            class="date-cs date-picker-style-max-length font-weight-bold font-14 font-md-14"
                            placeholder="Select date"
                            :disabled-dates="disabledDates"
                          >
                          </datepicker>
                          <span
                            v-if="errors[0].createSessionDateError"
                            class="text-danger d-inline-block"
                          >
                            {{ errors[0].createSessionDateError }}
                          </span>
                        </div>
                      </div>
                      <div class="pb-2">
                        <div class="col-lg-12 pt-md-2 pt-4 pb-0 px-0">
                          <label
                            class="font-18 font-md-16 font-weight-bold mb-0 pb-10p"
                            >Time</label
                          >
                        </div>
                        <div class="col-lg-12 pt-0 px-0">
                          <input
                            v-model="createStartTime"
                            type="text"
                            class="form-control text-light time-slot-cs font-weight-bold font-14 font-md-14 mx-0"
                            placeholder="HH:MM"
                            v-mask="timeMask"
                          />
                          <span
                            v-if="errors[0].createSessionTimeSlotError"
                            class="text-danger d-inline-block"
                          >
                            {{ errors[0].createSessionTimeSlotError }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer d-flex justify-content-center">
                      <button
                        type="button"
                        class="reset-button-filter px-10 py-2"
                        @click="gotoPreviousStep()"
                      >
                        Go Back
                      </button>
                      <button
                        type="button"
                        class="apply-button"
                        @click="addEvent()"
                      >
                        <span class="font-weight-bold apply">Apply</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CreateTemplateModal />
          <BatchTemplateModal v-on:refreshCalendar="updateCalendar()" />
          <BatchCancel
            v-on:refreshCalendar="updateCalendar()"
            :resetFilters="resetFilters"
          />
          <SessionInfoModal
            @closeModal="closeModal"
            :eventInfo="selectedEvent"
            @handleRefresh="handleRefresh"
          />
          <EditTemplateModal
            v-on:showEditTemplateTwo="showEditModalTwoFunction($event)"
          />
          <EditTemplateModalTwo
            v-if="showEditModalTwo"
            v-on:showEditTemplateTwo="showEditModalTwoFunction($event)"
          />
        </v-app>
        <div class="d-flex justify-content-center" v-else>
          <lottie-player
            autoplay
            loop
            mode="normal"
            class="check-in-animation"
            :src="check_in_arrow_json"
            style="width: 100px; height: 100px"
          ></lottie-player>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="import-shows"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-md mx-0 mx-md-auto"
        >
          <div class="modal-content animate-bottom br-18">
            <div class="p-0 modal-body br-18">
              <div
                class="modal-header mb-0 d-flex p-0 align-items-center import-modaal-header"
              >
                <span class="col-2 d-md-none"></span>
                <h5
                  class="modal-title font-md-18 font-16 px-0 pl-md-24 pb-12p pb-md-20 pt-md-24 pt-14p text-gold col-8 col-md-10 text-center text-md-left kallisto-bold"
                >
                  Import shows
                </h5>
                <button
                  type="button"
                  class="close m-0 col-2 pr-32 d-flex justify-content-end"
                  data-dismiss="modal"
                  style="border: none"
                >
                  <img
                    :src="getIcon('x-button.svg')"
                    alt="x-button"
                    class="x-button"
                  />
                </button>
              </div>
              <div class="mt-3 mb-32 pt-3 text-center">
                <label class="mt-4" v-if="showfileupload">
                  <input
                    id="fileUpload"
                    accept=".csv"
                    type="file"
                    ref="inputFile"
                    @input="setUploadFile()"
                    style="display: none"
                  />
                  <a
                    class="apply-button pl-18 pr-18 pt-12 pb-12"
                    style="border-radius: 8px"
                    ><img
                      class="px-2"
                      :src="getIcon('download-solid-black.svg')"
                      alt="check-solid"
                    />
                    <a
                      class="cursor-pointer font-14 font-md-16 text-dark os-bold"
                      >Choose a file to upload</a
                    ></a
                  >
                  <p class="font-14 font-weight-bold text-muted pt-24 mb-0">
                    Supported file type: CSV
                  </p>
                  <div
                    class="d-flex justify-content-center font-weight-bold mt-1 pt-5"
                  ></div>
                </label>
                <div v-else>
                  <div
                    class="p-3"
                    v-if="
                      fileStatus &&
                      fileStatus.inValidData &&
                      fileStatus.inValidData.length > 0
                    "
                  >
                    <a class="font-weight-bold font-18">Error:</a>
                    <p
                      class="font-20 font-weight-bold"
                      v-for="(errorMsg, em) in fileStatus.inValidData"
                      :key="em"
                    >
                      {{ errorMsg.message }}
                    </p>
                    <label class="mt-4">
                      <input
                        id="fileUpload"
                        accept=".csv"
                        type="file"
                        ref="inputFile"
                        @input="setUploadFile()"
                        style="display: none"
                      />
                      <a class="cursor-pointer">Upload a new file again</a>
                    </label>
                    <div
                      class="d-flex justify-content-center font-weight-bold mt-1 pt-5"
                    >
                      <button
                        class="mx-2 font-16 cancel"
                        data-dismiss="modal"
                        style="border: 1px solid"
                      >
                        Cancel
                      </button>
                      <button class="mx-2 btn-disabled font-16">
                        <a>Import</a>
                      </button>
                    </div>
                  </div>
                  <div v-else class="text-center mt-1">
                    <div v-if="index > 0" class="mb-3 font-20">
                      <p class="font-18 font-weight-bold mb-1">
                        Importing shows...
                      </p>
                      <p class="font-18">
                        {{ index }} of {{ fileStatus.data.length }}
                      </p>
                      <div
                        class="b-top-import-shows-resume mr-5 mr-md-100 ml-5 ml-md-100 mb-65"
                      >
                        <div
                          class="import-shows-resume"
                          :style="{
                            width:
                              '' +
                              getpercentage(index, fileStatus.data.length) +
                              '%',
                          }"
                        ></div>
                      </div>
                    </div>
                    <p class="font-16 os-semibold px-3" v-else>
                      Your file was validated. Do you want to import?
                    </p>
                    <div
                      v-if="index == 0"
                      class="d-flex justify-content-center font-weight-bold mt-5 pt-2"
                    >
                      <button
                        class="mx-2 py-2 px-4 font-16 cancel justify-content-center align-items-center d-flex"
                        data-dismiss="modal"
                        style="border: 1px solid"
                      >
                        Cancel
                      </button>
                      <button
                        class="mx-2 font-16 apply-button"
                        v-if="importEnabled"
                        @click="importShows(fileStatus.data)"
                      >
                        Import
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="import-sucess"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div class="p-0 modal-body br-18 b-gold">
              <div class="d-flex flex-column text-center">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Import was successful!
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                    @click="resetFilters"
                  >
                    Finish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="edit-template-sucess"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div
              class="p-0 modal-body"
              style="
                border: 0.5px solid #0b5786 !important;
                border-radius: 15px;
              "
            >
              <div class="d-flex flex-column text-center">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Template successfully edited
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="template-create-sucess"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div
              class="p-0 modal-body"
              style="
                border: 0.5px solid #0b5786 !important;
                border-radius: 15px;
              "
            >
              <div class="d-flex flex-column text-center">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Template successfully created
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="add-session-sucess"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div
              class="p-0 modal-body"
              style="
                border: 0.5px solid #0b5786 !important;
                border-radius: 15px;
              "
            >
              <div class="d-flex flex-column text-center">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Session successfully added !
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                    @click="resetFilters"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="batch-apply-sucess"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div
              class="p-0 modal-body"
              style="
                border: 0.5px solid #0b5786 !important;
                border-radius: 15px;
                box-shadow: 0px 3px 14px 0px rgba(184, 236, 255, 0.15);
              "
            >
              <div class="d-flex flex-column text-center pt-24">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Template successfully applied
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                    @click="resetFilters"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade px-0"
        id="cancel-shows-success"
        data-backdrop="exampleModalLabel"
        data-keyboard="false"
        tabindex="-1"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered w-custom"
          style="max-width: 716px; max-height: 344px"
        >
          <div class="modal-content animate-bottom br-18">
            <div
              class="p-0 modal-body"
              style="
                border: 0.5px solid #0b5786 !important;
                border-radius: 15px;
              "
            >
              <div class="d-flex flex-column text-center">
                <img
                  :src="getIcon('check-solid.svg')"
                  alt="checked"
                  class="checkin-complete mt-32"
                />
                <p class="font-14 font-md-18 font-weight-bold my-3 mb-38">
                  Shows successfully cancelled.
                </p>
                <div>
                  <button
                    data-dismiss="modal"
                    class="continue-btn os-bold mb-32 px-4"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSVFileValidator from "csv-file-validator";
import check_in_arrow_animation from "../../assets/icons/loader.json";
import {
  VRow,
  VCol,
  VSheet,
  VBtn,
  VMenu,
  VToolbar,
  VIcon,
  VToolbarTitle,
  VSpacer,
  VList,
  VListItem,
  VListItemTitle,
  VCalendar,
  VApp,
} from "vuetify/lib";
import { API } from "aws-amplify";
import getExpierence from "../../mixins/getSessionMixin";
import $ from "jquery";
import CreateTemplateModal from "@/components/instructor/CreateTemplateModal.vue";
import BatchTemplateModal from "@/components/instructor/BatchTemplateModal.vue";
import BatchCancel from "@/components/instructor/BatchCancel.vue";
import EditTemplateModal from "@/components/instructor/EditTemplateModal.vue";
import EditTemplateModalTwo from "@/components/instructor/EditTemplateModalTwo.vue";
import SessionInfoModal from "@/components/instructor/SessionInfoModal.vue";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    VRow,
    VCol,
    VSheet,
    VBtn,
    VMenu,
    VToolbar,
    VIcon,
    VToolbarTitle,
    VSpacer,
    VList,
    VListItem,
    VListItemTitle,
    VCalendar,
    VApp,
    CreateTemplateModal,
    BatchTemplateModal,
    BatchCancel,
    SessionInfoModal,
    EditTemplateModal,
    Datepicker,
    EditTemplateModalTwo,
  },
  mixins: [getExpierence],

  data() {
    return {
      dropdownOpen: false,
      check_in_arrow_json: JSON.stringify(check_in_arrow_animation),
      isPageRefreshComplete: false,
      pills: [],
      publicShows: false,
      hiddenShows: false,
      typeGroup: false,
      typeIndividual: false,
      selectedPods: [],
      selectedActs: [],
      calendarKey: 0,
      //Red Line on Calendar related data
      showfileupload: true,
      fileStatus: null,
      importEnabled: false,
      value: "",
      ready: false,
      //*********************
      showModal: false,
      calendarDate: null,
      //Create Event Modal Data *********************
      mode: "stack",
      createStartDate: "",
      createEndDate: "",
      createStartTime: "",
      createEndTime: "",
      createName: "",
      SC: this.$store.state.sessionsCalendar,
      showInfoSessionModal: false,
      selectedTitleId: "",
      selectedSubject: "All subjects",
      subjectId: "",
      selectedPod: "",
      reservationType: "individual",
      disabledDates: {
        to: new Date(Date.now() - 8640000),
        // days: [6, 0], // Disable Saturday's and Sunday's
      },

      //*********************

      //Vuetify Calendar Data *********************

      focus: "",
      type: "week",
      typeToLabel: {
        week: "Week",
        day: "Day",
        month: "Month",
      },
      selectedEvent: {},

      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ["#151515"],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      test: true,
      startDate: null,
      endDate: null,
      startMonth: null,
      endMonth: null,
      year: null,

      index: 0,

      //*********************
      showSessionInfo: false,
      templateName: "",
      isPublic: "",
      errors: [
        {
          createSessionActNameError: "",
          createSessionAvailabilityError: "",
          createSessionTimeSlotError: "",
          createSessionDateError: "",
          createSeatsAvailableError: "",
        },
      ],
      showEditModalTwo: false,
      selectedCourse: "",
      step: 1,
      recitation_session: false,
      recitation_details: {},
      recitation_sessions: [],
      showOptions: [],
    };
  },
  computed: {
    filteredEvents() {
      if (this.selectedSubject === "All subjects") {
        return this.getPodEvents();
      }
      const titles = this.getTitleIdByTlc(this.selectedSubject);
      return this.getPodEvents().filter((event) =>
        titles.some((item) => item.title_id === event.sessionData.title_id)
      );
    },

    filteredTitles() {
      if (this.subjectId) {
        return this.$store.state.titles.filter((i) => i.tlc === this.subjectId);
      } else {
        return this.$store.state.titles;
      }
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    availability() {
      return this.dynamicShow() ? "public" : "private";
    },
    isNextDisabled() {
      return (
        !this.selectedCourse &&
        !this.$store.state.calendarSelectedPod &&
        !this.selectedTitleId &&
        !this.reservationType
      );
    },
  },
  watch: {
    async type() {
      await this.getSessionBasedOnView();
    },
    typeIndividual() {
      this.pills = this.item();
    },
    typeGroup() {
      this.pills = this.item();
    },
    publicShows() {
      this.pills = this.item();
    },
    hiddenShows() {
      this.pills = this.item();
    },
    selectedPods(n1) {
      // if (n1 && n1.length > 0) {
      // n1.forEach((i) => this.updatePods(i));
      this.pills = this.item();
      // }
    },
    selectedActs() {
      this.pills = this.item();
    },
  },
  async mounted() {
    let vm = this;
    $("#add_a_session").on("hide.bs.modal", function () {});
    $("#add_a_session").on("show.bs.modal", function () {});
    try {
      this.showOptions = this.subjectOptions();
      this.$store.state.calDates = this.$refs.calendar;
      await this.$store
        .dispatch("setSessions")
        .then(async () => {
          this.$refs.calendar?.checkChange();
          await this.$store.dispatch("setSites");

          this.selectedPod = Object.keys(this.$store.state.scheduler_pods)[0];
          // await this.onPodChange();

          setTimeout(() => {
            this.$store.state.loader = false;
          }, 2000);
        })
        .catch((err) => {
          this.$store.state.loader = false;
        });
      this.pills = this.item();
      this.ready = true;
      this.scrollToTime();
      this.updateTime();
      $("#import-shows").on("hide.bs.modal", function () {
        vm.showfileupload = true;
        vm.importEnabled = false;
        vm.fileStatus = null;
        vm.index = 0;
      });
      this.isPageRefreshComplete = true;
    } catch (error) {
      console.log("errorrr", error);
    }
  },
  methods: {
    getCurrentDates() {
      const today = moment();
      const currentDay = today.format("YYYY-MM-DD");
      const startOfCurrentMonth = today.clone().startOf("month");
      const endOfCurrentMonth = today.clone().endOf("month");
      return {
        currentDay: currentDay,
        currentWeek: {
          start: startOfCurrentMonth.format("YYYY-MM-DD"),
          end: endOfCurrentMonth.format("YYYY-MM-DD"),
        },
        currentMonth: {
          start: startOfCurrentMonth.format("YYYY-MM-DD"),
          end: endOfCurrentMonth.format("YYYY-MM-DD"),
        },
      };
    },
    async onPodChange() {
      if (this.selectedPod) {
        const podData = this.$store.state.scheduler_pods[this.selectedPod];
        if (podData && podData.titles) {
          this.showOptions = [...new Set(podData.titles.map((t) => t.tlc))];
        } else {
          this.showOptions = [];
        }
      } else {
        this.showOptions = [];
      }
    },
    selectOption(option) {
      this.selectedSubject = option;
      this.dropdownOpen = false;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    getAbbreviatedMonthName(monthNumber) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return monthNames[monthNumber - 1];
    },
    getEvents(event) {
      let viewStart = moment(event.start.date);
      let viewEnd = moment(event.end.date);
      let startDate = viewStart.date();
      let endDate = viewEnd.date();
      let year = viewEnd.year();
      let startMonthName = viewStart.format("MMM");
      let endMonthName = viewEnd.format("MMM");
      this.startDate = startDate;
      this.endDate = endDate;
      this.startMonth = startMonthName;
      this.endMonth = endMonthName;
      this.year = year;
    },
    handleRefresh() {
      this.resetFilters();
    },
    onTypeChange(event) {
      this.type = event.target.value;
      this.$store.state.currentSelectedType = this.type;
    },
    getPodEvents() {
      let allSessions = [];
      for (let key in this.$store.state.scheduler_pods) {
        if (
          this.$store.state.scheduler_pods[key] &&
          this.$store.state.scheduler_pods[key].scheduler_sessions &&
          this.$store.state.scheduler_pods[key].scheduler_sessions.length > 0
        ) {
          this.$store.state.scheduler_pods[key].scheduler_sessions.forEach(
            (session) => {
              allSessions.push(session);
            }
          );
        }
      }
      return this.filterSessions(allSessions);
    },
    getTitleIdByTlc(tlc) {
      const title = this.$store.state.titles.filter(
        (title) => title.tlc === tlc
      );
      return title;
    },
    gotoPreviousStep() {
      this.step--;
    },
    emitresetFilters() {
      this.$emit("resetFilters");
    },
    resetFilters() {
      this.selectedPods = [];
      this.selectedActs = [];
      this.hiddenShows = false;
      this.publicShows = false;
      this.typeGroup = false;
      this.typeIndividual = false;
    },
    subjectOptions() {
      const options = this.$store.state.titles.map((i) => i.tlc);
      return [...new Set(options)];
    },
    item() {
      let pills = [];
      const schedulersessionsid = this.getPods(
        this.$store.state.SchedulerSessions
      );

      schedulersessionsid.forEach((item) => {
        if (this.selectedPods.includes(item)) {
          const podSplit = item.split("-");
          pills.push({
            text: `Pod ${podSplit[1]}`,
            value: `Pod ${podSplit[1]}`,
          });
        }
      });
      const selectedShows = this.$store.state.SchedulerSessions;
      if (selectedShows.length > 0) {
        selectedShows.forEach((show) => {
          if (
            this.selectedActs.includes(show.title_id) &&
            !pills.some((item) => item.value === show.title_id)
          ) {
            pills.push({
              text: show.title_name,
              value: show.title_id,
            });
          }
        });
      }
      if (this.typeGroup)
        pills.push({
          text: "Group",
          value: "Group",
        });
      if (this.typeIndividual)
        pills.push({
          text: "Individual",
          value: "Individual",
        });
      if (this.publicShows)
        pills.push({
          text: "Public shows",
          value: "Public shows",
        });
      if (this.hiddenShows)
        pills.push({
          text: "Hidden shows",
          value: "Hidden shows",
        });
      return pills;
    },
    removePill(index) {
      this.pills = this.pills.filter((i) => i.value != index);
      const schedulersessionsid = this.getPods(
        this.$store.state.SchedulerSessions
      );

      if (index == "Group") this.typeGroup = false;
      if (index == "Individual") this.typeIndividual = false;
      if (index == "Public shows") this.publicShows = false;
      if (index == "Hidden shows") this.hiddenShows = false;
      const [, splitStr] = index.split(" ");
      const siteid =
        this.$store.state.selectedSiteForScheduler.site_id + "-" + splitStr;
      if (schedulersessionsid.includes(siteid)) {
        const ind = schedulersessionsid.indexOf(siteid);
        const currentDeselectedPod = schedulersessionsid[ind];

        this.updatePods(currentDeselectedPod, false);
      }
      const selectedShow = this.$store.state.SchedulerSessions.some(
        (show) => show.title_id === index
      );
      if (selectedShow) {
        this.updateModule(index); // Update or remove the show from selected acts
      }
    },

    filterSessions(sessions) {
      if (this.pills.length === 0) {
        return sessions;
      }
      let filteredSessions = sessions;
      if (this.selectedActs.length > 0) {
        filteredSessions = filteredSessions.filter((act) =>
          this.selectedActs.includes(act.sessionData.title_id)
        );
      }
      if (this.selectedPods.length > 0) {
        filteredSessions = filteredSessions.filter((pod) =>
          this.selectedPods.includes(pod.sessionData.pod_id)
        );
      }
      if (this.hiddenShows && !this.publicShows) {
        filteredSessions = filteredSessions.filter(
          (session) =>
            session.sessionData.show_time_description === "hidden show"
        );
      } else if (this.publicShows && !this.hiddenShows) {
        filteredSessions = filteredSessions.filter(
          (session) =>
            session.sessionData.show_time_description === "public show"
        );
      }
      // Filter by session type
      if (this.typeGroup && !this.typeIndividual) {
        filteredSessions = filteredSessions.filter(
          (session) => session.sessionData.group_session === true
        );
      } else if (this.typeIndividual && !this.typeGroup) {
        filteredSessions = filteredSessions.filter(
          (session) => session.sessionData.group_session === false
        );
      }

      return filteredSessions;
    },
    RF() {
      this.$store.state.appointmentsRefresh = false;
      setTimeout(() => {
        this.$store.state.appointmentsRefresh = true;
      }, 100);
    },
    getPod(pod_id) {
      let temp_pod = pod_id.split("-");
      return `${temp_pod[1]}`;
    },
    getModules(sessions) {
      let modules = [];
      sessions.forEach((element) => {
        if (!modules.includes(element.title_id)) {
          modules.push(element.title_id);
        }
      });
      return modules;
    },
    updateModule(value) {
      if (this.selectedActs.includes(value)) {
        this.selectedActs = this.selectedActs.filter((e) => e !== value);
      } else {
        this.selectedActs.push(value);
      }
      this.RF();
    },
    updatePods(value, flag) {
      if (this.selectedPods.includes(value)) {
        this.selectedPods = this.selectedPods.filter((e) => e !== value);
      } else {
        this.selectedPods.push(value);
      }
      this.RF();
      !flag ? this.item(value) : null;
    },
    getActiveAudienceCount() {
      let count = 0;
      if (this.publicShows) count++;
      if (this.hiddenShows) count++;
      return count;
    },
    getActiveReservationCount() {
      let count = 0;
      if (this.typeGroup) count++;
      if (this.typeIndividual) count++;
      return count;
    },
    getPods(sessions) {
      let pods = [];
      if (sessions.length > 0) {
        sessions.forEach((element) => {
          if (!pods.includes(element.pod_id)) {
            pods.push(element.pod_id);
          }
        });
      }
      return pods;
    },
    dynamicShow() {
      const currTitle = this.$store.state.titles.find(
        (i) => i.shortname === this.selectedTitleId
      );
      // TODO: Band-aid fix for two different title object structures. One via title-mapping api, and other via titles api in SET_TTILES.
      return (
        currTitle &&
        ((currTitle.licensed_sites[0] &&
          currTitle.licensed_sites[0].ticketing_active_time != null) ||
          currTitle.ticketing_active_time != null)
      );
    },
    converToArray(obj) {
      const arr = Object.entries(obj).map(([key, value]) => {
        value.key = key; // Add the key to the object
        return value; // Return the modified object
      });
      return arr;
    },
    gotoNextStep3() {
      if (this.selectedTitleId) this.step = 3;
    },
    gotoNext() {
      if (
        this.$store.state.calendarSelectedPod &&
        this.subjectId &&
        this.reservationType
      )
        this.step = 2;
      // get recitations of the site_id
      this.getAllRecitations();
    },
    getAllRecitations() {
      let now = this;
      API.get(
        "proxy",
        `/${this.$store.state.selected_site_id}/dsl/common/asu/courses/recitations`
      ).then((res) => {
        now.recitation_sessions = res;
      });
    },
    getpercentage(index, total) {
      return Math.round((index / total) * 100);
    },
    closeModal() {
      this.selectedOpen = false;
    },
    async importShows(sessions) {
      sessions.map((item) => {
        item.public = item.available == "public" ? true : false;
        item.local_time = parseInt(item.local_time.replace(":", ""));
        item.capacity = parseInt(item.capacity);
        if (item.section_id != "") {
          item.is_recitation = true;
          item.tag_data = this.getRecitation(item);
        }
        delete item.available;
        delete item.PodNo;
        delete item.section_id;
        return item;
      });
      this.createMultipleSessions(sessions);
      // this.reccursive(sessions[0]);
    },
    getRecitation(item) {
      const recitation = this.recitation_sessions.find(
        (element) => element.recitation_section == item.section_id
      );
      let temp = {
        course: recitation.course,
        section_id: recitation.recitation_section,
        meeting_days: recitation.recitation_meeting_day,
        meeting_start_time: recitation.recitation_meeting_start_time,
        meeting_end_time: recitation.recitation_meeting_end_time,
        time_zone: "America/Phoenix",
        notes: {},
      };
      return temp;
    },
    createMultipleSessions(sessions) {
      API.put(
        "proxy",
        `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/sessions`,
        {
          body: sessions,
        }
      )
        .then(async () => {
          await this.$store.dispatch("setSessions").then(async () => {
            this.$refs.calendar?.checkChange();
            await this.$store.dispatch("setSites");
            this.$store.state.refresh = false;
            setTimeout(() => {
              // updateTitles

              this.$store.state.refresh = true;
            }, 2000);
          });
          $("#import-shows").modal("hide");
          $("#import-sucess").modal();
          this.$store.state.loader = false;
        })
        .catch(() => {
          this.$store.state.loader = false;
          $("#import-shows").modal("hide");
          this.$toasted.show(
            `Failed to import, check the requirements before importing`,
            {
              position: "top-right",
              duration: 5000,
            }
          );
        });
    },
    // Stored for future development
    // reccursive(session) {
    //   API.put(
    //     "proxy",
    //     `/${this.$store.state.selectedSiteForScheduler.site_id}/dsl/scheduler/sessions/`,
    //     {
    //       body: {
    //         site_id: session.site_id,
    //         title_id: session.title_id,
    //         schedule_date: session.schedule_date,
    //         local_time: parseInt(session.local_time.replace(":", "")),
    //         public: session.public === "public" ? true : false,
    //         gearup_id: session.gearup_id,
    //         capacity: parseInt(session.capacity),
    //         reservation_type: session.reservation_type,
    //         recitation_details: {
    //           course: session.course,
    //           section_id: session.section_id,
    //           meeting_days: session.meeting_days,
    //           meeting_start_time: session.meeting_start_time,
    //           meeting_end_time: session.meeting_end_time,
    //           time_zone: "America/Phoenix",
    //           notes: {},
    //         },
    //       },
    //     }
    //   )
    //     .then(async () => {
    //       if (this.fileStatus.data.length - 1 > this.index) {
    //         this.reccursive(this.fileStatus.data[this.index + 1]);
    //         this.index++;
    //       } else {
    //         await this.$store.dispatch("setSessions").then(async () => {
    //           this.$refs.calendar.checkChange();
    //           await this.$store.dispatch("setSites");
    //           this.$store.state.refresh = false;
    //           setTimeout(() => {
    //             // updateTitles
    //             let temptitles =
    //               this.$store.state.scheduler_pods[0].pods[0].titles;
    //             if (this.$store.state.calendarSelectedPod == "1") {
    //               this.$store.state.titles = temptitles;
    //             }
    //             this.$store.state.refresh = true;
    //           }, 2000);
    //         });
    //         $("#import-shows").modal("hide");
    //         $("#import-sucess").modal();
    //         this.$store.state.loader = false;
    //       }
    //     })
    //     .catch((err) => {
    //       if (this.fileStatus.data.length - 1 > this.index) {
    //         this.reccursive(this.fileStatus.data[this.index + 1]);
    //         this.index++;
    //       } else {
    //         this.$store.state.loader = false;
    //         $("#import-shows").modal("hide");
    //         this.$toasted.show(
    //           `Failed to import, check the requirements before importing`,
    //           {
    //             position: "top-right",
    //             duration: 5000,
    //           }
    //         );
    //       }
    //     });
    // },
    isTitleIdValidate(title_id, row) {
      return true;
      // let status = false;
      // let vm = this;
      // if (title_id && row[6]) {
      //   vm.listActPods.forEach((pod) => {
      //     if (pod.gearup_id == row[6]) {
      //       if (pod.titlesIds.includes(title_id)) {
      //         status = true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   });
      // }
      // return status;
    },
    isDateValid(dateString) {
      var regEx = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateString.match(regEx)) return false; // Invalid format
      var d = new Date(dateString);
      var dNum = d.getTime();
      if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
      return d.toISOString().slice(0, 10) === dateString;
    },
    isCapacityValidate(capacity, row) {
      return true;
      // let status = false;
      // let vm = this;
      // if (capacity && row[5]) {
      //   vm.listActPods.forEach((pod) => {
      //     if (pod.gearup_id == row[6]) {
      //       if (pod.capacity >= capacity) {
      //         status = true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   });
      // }
      // return status;
    },
    validateCSV(file) {
      let vm = this;
      const config = {
        headers: [
          {
            name: "PodNo",
            inputName: "PodNo",
            required: true,
            requiredError: function (PodNo, rowNumber, columnNumber) {
              return `${PodNo} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "title_id",
            inputName: "title_id",
            required: true,
            requiredError: function (title_id, rowNumber, columnNumber) {
              return `${title_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            dependentValidate: function (gearup_id, row) {
              return vm.isTitleIdValidate(gearup_id, row);
            },
            validateError: function (title_id, rowNumber, columnNumber) {
              return `Title id is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "available",
            inputName: "available",
            required: true,
            requiredError: function (available, rowNumber, columnNumber) {
              return `${available} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },

          {
            name: "schedule_date",
            inputName: "schedule_date",
            required: true,
            requiredError: function (schedule_date, rowNumber, columnNumber) {
              return `${schedule_date} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            validate: function (schedule_date) {
              return vm.isDateValid(schedule_date);
            },
            validateError: function (schedule_date, rowNumber, columnNumber) {
              return `${schedule_date} is not in valid format the ${rowNumber} row / ${columnNumber} column expected in YYYY-MM-DD`;
            },
          },
          {
            name: "local_time",
            inputName: "local_time",
            required: true,
            requiredError: function (local_time, rowNumber, columnNumber) {
              return `${local_time} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "capacity",
            inputName: "capacity",
            required: true,
            requiredError: function (capacity, rowNumber, columnNumber) {
              return `${capacity} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
            dependentValidate: function (capacity, row) {
              return vm.isCapacityValidate(capacity, row);
            },
            validateError: function (title_id, rowNumber, columnNumber) {
              return `Capacity is not valid in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "gearup_id",
            inputName: "gearup_id",
            required: true,
            requiredError: function (gearup_id, rowNumber, columnNumber) {
              return `${gearup_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "site_id",
            inputName: "site_id",
            required: true,
            requiredError: function (site_id, rowNumber, columnNumber) {
              return `${site_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "reservation_type",
            inputName: "reservation_type",
            required: true,
            requiredError: function (
              reservation_type,
              rowNumber,
              columnNumber
            ) {
              return `${reservation_type} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
          {
            name: "section_id",
            inputName: "section_id",
            required: false,
            requiredError: function (section_id, rowNumber, columnNumber) {
              return `${section_id} is required in the ${rowNumber} row / ${columnNumber} column`;
            },
          },
        ],
        isHeaderNameOptional: true,
      };
      let now = this;
      CSVFileValidator(file, config)
        .then((csvStatus) => {
          now.fileStatus = csvStatus;
          if (
            csvStatus &&
            csvStatus.inValidData &&
            csvStatus.inValidData.length == 0
          ) {
            now.importEnabled = true;
          }
          // csvStatus.data // Array of objects from file
          // csvStatus.inValidMessages // Array of error messages
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setUploadFile() {
      this.getAllRecitations();
      this.getActPods();
      this.showfileupload = false;
      this.importEnabled = false;
      this.fileStatus = null;
      const file = this.$refs.inputFile.files[0];
      if (file.type == "text/csv") {
        this.validateCSV(file);
        // Api call
      } else {
        alert("error", "The file-type doesn't belong to text/csv");
      }
    },
    getActPods() {
      let podTitles = [];
      for (let key in this.$store.state.scheduler_pods) {
        let tempObj = {
          gearup_id: this.$store.state.scheduler_pods[key].gear_id,
          pod_id: `ASU0000${this.$store.state.scheduler_pods[key].key}`,
          capacity: this.$store.state.scheduler_pods[key].max_users,
          titlesIds: [],
        };
        if (
          this.$store.state.scheduler_pods[key].titles &&
          this.$store.state.scheduler_pods[key].titles.length > 0
        ) {
          this.$store.state.scheduler_pods[key].titles.forEach((element) => {
            if (!tempObj.titlesIds.includes(element.title_id)) {
              tempObj.titlesIds.push(element.title_id);
            }
          });
        }
      }
      // dump.forEach((element) => {
      //   let tempObj = {
      //     gearup_id: element.gearups[0].gearup_id,
      //     pod_id: element.pod_id,
      //     capacity: element.max_users,
      //     titlesIds: [],
      //   };
      //   if (element.titles && element.titles.length > 0) {
      //     element.titles.forEach((title) => {
      //       tempObj.titlesIds.push(title.title_id);
      //     });
      //   }
      //   podTitles.push(tempObj);
      // });
      this.listActPods = podTitles;
    },
    getCurrentDate() {
      return {
        dates: [
          // your date
          new Date(Date.now()),
        ],
      };
    },
    start_and_end(str) {
      if (str.length > 20) {
        return (
          str.substr(0, 9) + "..." + str.substr(str.length - 5, str.length)
        );
      }
      return str;
    },

    showEditModalTwoFunction(show) {
      if (show) {
        this.showEditModalTwo = true;
        $("#editTemplate-2").modal("show");
      } else {
        $("#editTemplate-2").modal("hide");
        this.showEditModalTwo = false;
      }
    },
    timeMask(value) {
      const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2 ? [...hours, ":", ...minutes] : hours;
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    getParsedTime(time) {
      return moment(time, "HH:mm:ss").format("hh:mm A");
    },
    sessionOpacity(event) {
      var eventDate = moment(event.start);
      var today = moment().tz("America/Phoenix").format();
      var endDT = eventDate.tz("America/Phoenix").format();
      var sessionInPast = moment(endDT).isBefore(today);

      if (sessionInPast) {
        return "session-opacity-past";
      } else {
        return "session-opacity";
      }
    },
    pastDate() {
      var today = moment().tz("America/Phoenix").format();
      var eventDate = moment(event.start);
      var endDT = eventDate.tz("America/Phoenix").format();
      var inPast = moment(endDT).isBefore(today);

      return inPast;
    },
    showAddedSessions() {
      $("#sessionsAdded").modal("show");
    },
    borderOpacity(event) {
      var today = moment().tz("America/Phoenix").format();
      var eventDate = moment(event.start);
      var endDT = eventDate.tz("America/Phoenix").format();
      var sessionInPast = moment(endDT).isBefore(today);

      if (sessionInPast) {
        return "event-border-color d-inline-block float-left";
      } else {
        return "event-border-color d-inline-block float-left";
      }
    },
    async openModal() {
      await this.$store
        .dispatch(
          "getCalendarSessionOrders",
          this.selectedEvent.sessionData.session_id
        )
        .then(() => {
          $("#sessionInfoModal").modal("show");
        });
    },
    toggleShowSessionInfoBoolean() {
      this.showSessionInfo = false;
    },
    onChangePod(event, bool) {
      this.$store.state.calendarSelectedPod = event.target.value;
      this.$store.state.calendarSelectedGearup =
        this.$store.state.scheduler_pods[event.target.value].gearups[0];
    },
    onChangeCourse(event) {
      this.$store.state.selectedCourseForScheduler = event.target.value;
      this.selectedCourse = event.target.value;

      this.$store.state.titles =
        this.$store.state.scheduler_pods[0].pods[
          this.$store.state.calendarSelectedPod - 1
        ].titles;
    },
    async updateCalendar() {
      await this.$store.dispatch("setSessions").then(() => {
        this.$refs.calendar?.checkChange();
      });
    },
    openCreateSessionModal(tms) {
      if (this.selectedOpen == false) {
        $("#createSession").modal("show");
        this.createStartDate = tms.date;
        this.createEndDate = tms.date;
        this.createStartTime = tms.time;
        this.createEndTime = tms.time;
        this.createName = "";
      }
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    selectedDate(date) {
      this.focus = moment(date).format("YYYY-MM-DD");
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    async prev() {
      await this.$refs.calendar.prev();

      await this.getSessionBasedOnView();
    },
    async next() {
      await this.$refs.calendar.next();
      await this.getSessionBasedOnView();
    },

    async getSessionBasedOnView() {
      await this.$refs.calendar?.checkChange();

      let myData = {
            selected_site_id: this.$store.state.selected_site_id,
      };
      const { currentDay, currentWeek, currentMonth } = this.getCurrentDates();
      if (this.$store.state.currentSelectedType === "day" && currentDay) {
        myData.start_time = currentDay;
        myData.end_time = currentDay;
      }
      if (this.$store.state.currentSelectedType === "week" && currentWeek) {
        myData.start_time = currentWeek.start;
        myData.end_time = currentWeek.end;
      }
      if (this.$store.state.currentSelectedType === "month" && currentMonth) {
        myData.start_time = currentMonth.start;
        myData.end_time = currentMonth.end;
      }
       await this.$store
        .dispatch("setCalendarSessionsWithStartAndEndDate", myData)
        .then(() => {
          this.$store.state.refresh = true;
          this.isPageRefreshComplete = true;
          this.$store.state.loader = false;
          this.resetFilters();
        })

        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
          this.openModal();
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        this.selectedEvent = "";
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
      this.openModal();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    async addEvent() {
      if (
        this.selectedTitleId == "" ||
        this.selectedTitleId == "Choose an act"
      ) {
        this.errors[0].createSessionActNameError = "Act name cannot be empty.";
      } else {
        this.errors[0].createSessionActNameError = "";
      }
      if (this.createStartTime.length < 5) {
        this.errors[0].createSessionTimeSlotError =
          "Please use the correct format";
      } else {
        this.errors[0].createSessionTimeSlotError = "";
      }

      if (this.createStartDate == "") {
        this.errors[0].createSessionDateError = "Please select a date";
      } else {
        this.errors[0].createSessionDateError = "";
      }

      if (
        this.errors[0].createSessionActNameError != "" ||
        this.errors[0].createSessionTimeSlotError != "" ||
        this.errors[0].createSessionAvailabilityError != "" ||
        this.errors[0].createSessionDateError != ""
      ) {
        return -1;
      } else {
        $("#createSession").modal("hide");

        let timeSplit = this.createStartTime.toString().split(":");
        let timeSlotToPost = timeSplit[0] + timeSplit[1];

        let gearupId = this.$store.state.calendarSelectedGearup.gearup_id;
        let capacity = this.$store.state.calendarSelectedGearup.max_users;
        let titleId = "";
        let subject = "";

        let getStatus = this.$store.state.titles.find(
          (status) => status.shortname === this.selectedTitleId
        );
        if (getStatus != undefined) {
          if (getStatus.shortname == this.selectedTitleId) {
            titleId = getStatus.title_id;
            subject = getStatus.tlc;
            capacity = getStatus.max_player_count;
          }
        }
        let start = new Date(moment(this.createStartDate).format("YYYY-MM-DD"));
        let startDate = new Date(start);
        let details = {
          addASessionBody: {
            body: {
              tlc: subject,
              site_id: this.$store.state.selected_site_id,
              title_id: titleId,
              schedule_date: `${startDate
                .toISOString()
                .substr(0, startDate.toISOString().indexOf("T"))}`, //embedded in start_timestamp
              local_time: parseInt(timeSlotToPost), // embedded in start_timestamp
              public: this?.availability == "private" ? false : true, // showtype = 1600 (public false would yield 1620)
              gearup_id: gearupId,
              capacity: Math.min(
                getStatus.max_player_count,
                this.$store.state.calendarSelectedGearup.max_users
              ),
              reservation_type: this.reservationType,
              is_recitation: this.recitation_details.section_id ? true : false,
              tag_data: this.recitation_details
                ? this.recitation_details
                : null,
            },
          },
          myData: {
            selected_site_id: this.$store.state.selected_site_id,
            start_time: this.$refs.calendar.lastStart.date,

            end_time: this.$refs.calendar.lastEnd.date,
          },
        };
        this.isPageRefreshComplete = false;

        await this.$refs.calendar?.checkChange();
        await this.$store
          .dispatch("addASession", details)
          .then(async (response) => {
            this.isPageRefreshComplete = true;

            if (response.status) {
              this.$toasted.show(`Error : ${response.data.message}`, {
                position: "top-right",
                duration: 5000,
              });
              this.step = 1;
            } else {
              $("#add-session-sucess").modal();
              this.isPageRefreshComplete = true;

              this.step = 1;
            }
            this.emptyCreateSessionModalData();
          });
      }
    },

    emptyCreateSessionModalData() {
      this.selectedTitleId = "";
      this.createStartDate = "";
      this.createStartTime = "";
      this.availability = "public";
    },
    onChangeSubject() {
      // this.$store.state.titles = this.$store.state.titles.filter(
      //   (item) => item.tlc === this.subjectId
      // );
    },
    onChangeAct(event) {
      this.selectedTitleId = event.target.value;
    },
    onRecitation(event) {
      const recitation = this.recitation_sessions.find(
        (element) => element.recitation_section == event.target.value
      );
      this.createStartTime = recitation.recitation_meeting_start_time;
      this.recitation_details = {
        course: recitation.course,
        section_id: recitation.recitation_section,
        meeting_days: recitation.recitation_meeting_day,
        meeting_start_time: recitation.recitation_meeting_start_time,
        meeting_end_time: recitation.recitation_meeting_end_time,
        time_zone: "America/Phoenix",
        notes: {},
      };
    },
    onChangeAvailabilty(event) {
      this.availability = event.target.value;
    },
    onChangeReservationType(event) {
      this.reservationType = event;
    },
    onChangeRecitation(event) {
      this.recitation_session = event;
    },
    customFormatter() {
      var start = new Date(moment(this.createStartDate).format("YYYY-MM-DD"));
      var startDate = new Date(start);
      return startDate
        .toISOString()
        .substr(0, startDate.toISOString().indexOf("T"));
    },

    startTime() {},
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },
    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },
    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },
  },
};
</script>

<style lang="scss">
.selectstyle {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("../../assets/icons/chevron-down-solid.svg");
  background-repeat: no-repeat;
  background-position-x: 420px;
  background-position-y: 20px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
}

.vdp-datepicker {
  input {
    width: 0px !important;
    height: 34px !important;
    border-radius: 5px;
    margin-left: 0px;
    color: white;
    text-align: center;
    background-color: #2d2d2d !important;
    border: none !important;
  }
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}

.moduleAct-corses {
  background: #fafafa !important;
  color: #151515 !important;
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
}

.dropdown-options {
  position: absolute;
  background: #2c2c2c;
  border-radius: 2px;
  z-index: 1000;
  width: 252px !important;
}

.dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-options li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-options li:hover {
  background: #e0e0e0;
}

.dropdown-options {
  position: absolute;
  border-radius: 2px;
  z-index: 1000;
  width: 100%;
}

.dropdown-options ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown-options li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-options li:hover {
  background: #0e4f74;
}

.v-calendar-daily__scroll-area {
  background: #0e0e0e !important;
}
.hover-button {
  background: #2c2c2c !important;
  color: #fff !important;
}
.hover-button:hover {
  background: #0e4f74 !important;
  color: #fff !important;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.custom-select {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  padding: 10px;
  border: 1px solid #333; /* Slightly lighter border for contrast */
  cursor: pointer;
  border-radius: 4px; /* Optional: rounded corners */
}

.custom-select .selected-option {
  font-size: 16px; /* Adjust font size as needed */
}

.arrow {
  margin-left: 10px;
}

.options-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  border: 1px solid #333; /* Border to match the custom select */
  border-radius: 4px;
  display: none; /* Hide by default */
  z-index: 10; /* Ensure it appears above other elements */
}

.options-wrapper.active {
  display: block; /* Show when active */
}

.option {
  padding: 10px;
  cursor: pointer;
  font-size: 16px; /* Adjust font size as needed */
}

.option:hover {
  background-color: #333; /* Slightly lighter background on hover */
}
select option {
  color: #fff !important;
  background: black;
}
.arrow-calendar {
  position: absolute;
  right: 10px; /* Adjust based on your layout */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000; /* Dark color for the arrow */
}
select,
select option {
  .moduleAct {
    color: #000 !important;
  }
}
.dropdown-select {
  width: 100%;
  max-width: 200px;
  background-color: white;
  border-radius: 8px;
  border: 0.5px solid #707070 !important;
  padding: 5px;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Remove default arrow in Safari */
  -moz-appearance: none; /* Remove default arrow in Firefox */
  cursor: pointer;
  position: relative;
  padding-right: 30px; /* Space for the custom arrow */
}

.dropdown-select::after {
  content: "\25BC"; /* Down arrow */
  font-size: 16px;
  color: #707070;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none; /* Ignore clicks on the arrow */
}
.dropdown-select {
  background: white !important;
  color: black !important;
}
.dropdown-wrapper {
  position: relative;
  display: inline-block;
}
.moduleAct {
  background: #fafafa !important;
  color: #151515 !important;
  padding: 4px 8px 4px 4px;
  border-radius: 2px;
}
.moduleAct-corses {
  background: #fafafa !important;
  color: #151515 !important;
  padding: 4px 4px 4px 4px;
  height: 38px;
  border-radius: 2px;
}
.reset-button-filter {
  background: #313131 !important;
  color: #f1f1f1;
  border-radius: 8px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 16px;
}
.v-chip__close {
  color: white !important;
}
.v-chip.v-size--default {
  font-size: 12px !important;
}
.theme--light.v-chip:not(.v-chip--active) {
  background: #313131 !important;
  color: #f1f1f1;
}
.check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}

/* Hide the default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Custom checkbox */
.checkmark-mobile-scheduler {
  position: absolute;
  top: 1px !important;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 4px; /* Rounded corners */
  border: #0e4f74;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark-mobile {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark-mobile {
  background-color: #2196f3 !important;
}

/* Checkmark/indicator (hidden when not checked) */
.checkmark-mobile:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark-mobile:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-container .checkmark-mobile:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  border: #0e4f74;
}
.session-date-selector-scheduler {
  position: absolute !important;
  input {
    padding-left: 15px !important;
    cursor: pointer !important;
    background-color: transparent !important;
    border: none !important;
    width: 25px;
    color: transparent !important;
    &:focus {
      outline: none !important;
    }
  }
  .prev {
    background-color: grey !important;
    left: 12%;
  }

  .next {
    background-color: grey !important;
    right: 12%;
  }
}
.b-top-import-shows-resume {
  border-radius: 10px;
  height: 16px;
  background: #2d2d2d;

  .import-shows-resume {
    border-radius: 10px;
    height: 16px;
    background: #ffc627;
  }
}

.scheduler-bg {
  height: 209px;
  background: linear-gradient(0deg, #0e0e0e -2.78%, rgba(14, 14, 14, 0) 83.12%),
    url("../../assets/images/scheduler_bg.png");
  background-size: cover;
  background-position: center;
}
.w-custom {
  max-width: 512px !important;
}
.checkin-complete {
  background: #d1a323;
  border-radius: 60px;
  padding: 12px;
  height: 45px;
  width: 45px;
  align-self: center;
}
.continue-btn {
  border-radius: 10px !important;
  font-size: 16px !important;
  width: 136px;
  height: 42px !important;
  font-weight: bold;
  color: #000000 !important;
  background: #ffc627;
}
#sessionSettings {
  @keyframes animateleft {
    from {
      left: -300px;
      opacity: 0;
    }

    to {
      left: 0;
      opacity: 1;
    }
  }
  .modal-dialog {
    width: 75%;
    height: 100%;
  }
  .modal-content {
    height: 100%;
  }
}
.animate-left {
  position: relative;
  animation: animateleft 0.4s;
}
.custom-table {
  position: relative;
  z-index: 1;
}
.custom-v-head {
  position: relative;
  z-index: 10;
}
@media only screen and (max-width: 767px) {
  .scheduler-bg {
    height: 90px;
    margin-bottom: 30px;
  }
  .choose-an-act {
    height: 40px !important;
    border-radius: 0px !important;
  }
  .border-bottom-gold {
    border-bottom: 1px solid #93751f80 !important;
  }
  .v-calendar.border-0.v-calendar-daily.theme--dark {
    width: 1200px;
  }

  .custom-table {
    overflow: scroll !important;
  }
  .custom-table::-webkit-scrollbar {
    display: none;
  }
  .custom-table {
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
  .modal-dialog .modal-dialog {
    height: 626px;
    width: 522px;
  }
}
.v-current-time.first:before {
  background-color: transparent !important;
}
.dropdown-menu-options {
  transform: translate3d(0px, 42px, 0px) !important;
  min-width: 174px !important;
  button {
    padding: 10px 20px;
    border-radius: 2px;
  }
  button:hover {
    background: #0e4f74 !important;
    color: #ffff !important;
  }
}

.dropdown-menu-session-options {
  transform: translate3d(171px, 42px, 0px) !important;
  min-width: 135px !important;
  button {
    padding: 10px 20px;
    border-radius: 2px;
  }
  button:hover {
    background: #0e4f74;
  }
}
.font-27 {
  font-size: 27px;
}
#import-shows {
  .apply-button,
  .import {
    color: #000000 !important;
    background: #ffc627;
    border-radius: 10px;
    height: 42px;
    width: 136px;
  }
  .btn-disabled {
    border: 1px solid #ffc627;
    border-radius: 10px;
    height: 42px;
    width: 136px;
  }
  .cancel {
    border-radius: 10px;
    height: 42px;
    width: 136px;
  }
  @media only screen and (max-width: 767px) {
    .import-modaal-header {
      border-bottom: 0.5px solid #93751f80 !important;
    }
    button {
      border: 1px solid #ffc627;
      border-radius: 6px !important;
      padding: 3px 36px !important;
      height: 30px;
      width: 120px;
    }
    input,
    select {
      max-width: 450px;
      height: 32px !important;
      border: none;
      background-color: #2d2d2d !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      color: #000000 !important;
      background: #ffc627;
    }
    .apply-button,
    .cancel,
    .apply-button-disabled {
      border-radius: 6px !important;
      font-size: 16px !important;
      height: 38px !important;
      width: 130px !important;
    }
    .modal-body {
      border-radius: 18px;
    }
  }
  .modal-body {
    border: 1px solid #93751f;
    border-radius: 18px;
  }
}
body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}
.modal {
  input {
    background-color: #151515;
  }
  input:focus {
    background-color: #151515;
  }
  select:focus {
    background-color: #151515;
  }
  .modal-content {
    background-color: #151515;
  }
}
.import-btn-cal:hover,
#templateOptions:hover,
.templating-buttons:hover {
  .hide-hover {
    display: none !important;
  }
  .show-hover {
    display: inline !important;
  }
}

#templateOptions,
.templating-buttons {
  .show-hover {
    display: none;
  }
}
.import {
  color: #ffc627;
}
.import:hover {
  color: #252525 !important;
  background: #ffc627 !important;
}
.import-btn-cal {
  color: #ffc627;
  &:hover {
    color: #252525;
    background: #ffc627 !important;
  }
  .show-hover {
    display: none;
  }
}

#createSession {
  select,
  input {
    background-color: #2d2d2d !important;
    border: none !important;
    border-radius: 0px;
  }
  .date-cs.vdp-datepicker {
    input {
      width: 210px !important;
      height: 42px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    input,
    select {
      max-width: 450px;
      height: 40px !important;
      background-color: #2d2d2d !important;
      border: none !important;
    }
    .modal-footer {
      border-top: none;
    }
    .apply-button {
      color: #000000 !important;
      background: #ffc627;
    }
    .apply-button,
    .apply-button-disabled {
      border-radius: 6px !important;
      font-size: 16px !important;
      height: 38px !important;
      width: 262px !important;
    }
  }
  .time-slot-cs {
    max-width: 200px !important;
    height: 42px !important;
    margin-left: 12px !important;
  }

  .availability-ctm {
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    max-width: 113px;
    height: 41px;
    background-color: #151515 !important;
  }
  .apply-button {
    border: 1px solid #ffc627 !important;
    color: #000000 !important;
    font-size: 16px !important;
    border-radius: 10px;
    background: #ffc627;
    width: 153px;
    height: 42px;
  }
  .modal-footer {
    border-top: none;
  }
  input:focus {
    background-color: #151515;
  }
  select:focus {
    background-color: #151515;
  }
  select {
    background-color: #151515;
    color: #ffffff;
    option {
      color: white;
    }
  }
}

.form-control {
  width: 100% !important;
}
.arrow-styling {
  background-color: #2d2d2d !important;
  padding: 4px;
  margin-left: -25px;
}
.choose-an-act {
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 0px;
  text-align: center;
}

.to-from-dates {
  width: 140px;
  height: 53px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  margin-left: 16px;
  color: white;
}

.theme--dark.v-calendar-daily {
  background-color: #0e0e0e !important;
  border-right: 1px solid #484848 !important;
  border-bottom: 1px solid #484848 !important;
}
input:focus {
  background-color: #151515;
}
select:focus {
  background-color: #151515;
}
select {
  background-color: #151515;
}

.event-border-color {
  width: 5px;
  background: #ffc627;
  height: 100%;
}

.template-v-for {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
}

.theme--light.v-toolbar.v-sheet {
  .v-toolbar__content {
    padding-left: 0px;
    padding-right: 0px;
  }
  background-color: #151515;
  color: white;
}

.templating-buttons {
  background-color: #313131;
  border: none;
  border-radius: 4px;
  margin-right: 15px;
  font-size: 14px;
  padding: 6px 12px;
  height: 35px;
  color: #fff;
  display: flex;
  align-items: center;
}
.sessions-buttons {
  background-color: #313131;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 12px;
  height: 35px;
  color: #fff;
  display: flex;
  align-items: center;
}
.sessions-buttons:hover {
  color: black !important;
  background: white;
}
.templating-buttons:hover,
.cancel:hover {
  color: black !important;
  background: white;
}
.theme--light.v-sheet {
  background-color: #0e0e0e !important;
}

.pod-select {
  background-color: transparent !important;
}

.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.theme--dark.v-calendar-weekly {
  background-color: #0e0e0e !important;
}

.theme--light.v-sheet {
  background-color: #0e0e0e !important;
}

.v-calendar-daily__body {
  background-color: #0e0e0e !important;
}

.v-calendar .v-event {
  border: 0.5px solid #707070;
  border-radius: 0px !important;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem !important;
}

.v-event.v-event-start.v-event-end.white--text {
  border: 0.5px solid #707070 !important;
  border-radius: 0px !important;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem !important;
}

div .theme--dark.v-calendar-events .v-event-timed {
  border-radius: 0px !important;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem !important;
  border: 0.5px solid #707070 !important;
}

.theme--dark.v-calendar-daily {
  background-color: #0e0e0e !important;
}

.calendar-pod {
  background: #fff !important;
  height: 36px;
  border-radius: 5px;
  padding-top: 2px;
}

.disabled-date {
  background-color: #151515 !important;
}

.session-opacity-past {
  opacity: 0.5;
}

.session-opacity {
  opacity: 1 !important;
}
.pointer {
  cursor: pointer;
}
.select-date-type {
  padding: 8px 18px;
  font-size: 12px;
  font-weight: bold;
}
.active-date-type {
  background: #d0d0d0;
  color: #151515;
  border-radius: 8px;
}
.v-calendar-daily_head-weekday {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}
#app {
  .v-application .primary--text {
    color: #007dc5 !important;
  }
}
.v-calendar-daily__interval-text {
  font-weight: bolder;
}
.notranslate {
  background: #2d2d2d;
  border-radius: 50%;
  height: 34px;
  width: 34px;
}
@media only screen and (min-width: 768px) {
  #import-shows {
    .modal-content {
      min-width: 516px !important;
    }
  }
  #add_a_session.modal-content {
    border-radius: 16px !important;
    height: 624px;
  }
  .modal .modal-content {
    background-color: #0e0e0e !important;
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
  #add_a_session.modal-content {
    border-radius: 16px !important;
    height: 624px;
  }

  #desktopFilterModal .modal-content {
    border-radius: 20px !important;
    height: 658px;
  }
  #filter-collapse-mobile {
    height: 563px;
    overflow: scroll;
    overflow-x: overlay;
  }
}

.date-picker-style-max-length.vdp-datepicker {
  input {
    width: 100% !important;
    max-width: 100% !important;
    height: 53px !important;
  }
}
</style>
